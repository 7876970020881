const UserReducer = (state, action) => {
    switch(action.type) {
        case 'update':
            return {...state, ...action.payload}
        default:
            return state;
    }
}
export const initialState = {email: '', tepephone: '', fullname: ''}

export default UserReducer;
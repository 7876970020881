import { useEffect, useRef } from "react"

const LocationService = ({watch, locationUpdate, locationError =() =>{}}) => {
    let watchid = useRef(null);

    useEffect(() => {
        if(!navigator.geolocation) {
            alert('Location not enabled');
        } 
    });

    useEffect(()=>{
        if(watch) {
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000
        }
        watchid.current =  navigator.geolocation.watchPosition(success, errorCallBack, options)
    } else {
        if(watchid.current) {
            navigator.geolocation.clearWatch(watchid.current);
        }
    }

    return () => {
        if(watchid.current) {
            navigator.geolocation.clearWatch(watchid.current);
        }
    }
    }, [watch])

    const success = (pos) => {
       const coords =  pos.coords;
       locationUpdate(coords);

    }

    const errorCallBack = (err) => {
        locationError(err.message)
    }
    return (<>
    </>)
}

export const GetCurrentLocation = (callBack)=> {
    if(!navigator.geolocation) {
        alert('No location service');
    }
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
    navigator.geolocation.getCurrentPosition((pos) => callBack(pos.coords), (err) => {
        console.log(err);
    }, options);
}


export const gettCurrentLocationAsync = () => {
    return new Promise((resolve, reject) => {
        if(!navigator.geolocation) {
           reject('No location service');
        }
        const options = {
           //uncomment for gps enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
        console.log('GETTING COORDS');
        navigator.geolocation.getCurrentPosition((pos) => resolve(pos.coords), (err) => {
            reject(err);
        }, options);

    });
}


export const watchPosition = (cb, err) => {
    const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000
    }
   return  navigator.geolocation.watchPosition(cb, err, options);

}

export const watchPositionAsync = () => {
    return new Promise((resolve, reject) => {
        if(!navigator.geolocation) {
            reject('No location service');
         }
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000
        }
        const wid = navigator.geolocation.watchPosition(pos => {
            resolve({wid, coords: pos.coords})

        }, err => {
            reject(err);
        },options); 
    })
}


export const ToRad = (num)=> {
   return num * Math.PI / 180;
}


export default LocationService
import { useCallback, useEffect, useRef, useState } from "react"
import '../styles/form-fields.css';

const css = styleBlock => {
    const styleEl =  document.createElement('style');
    styleEl.textContent = `${
        styleBlock
    }`
    document.head.appendChild(styleEl);
};

const ddSvg = <svg  xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M9 16.5q-1.05 0-1.775-.725Q6.5 15.05 6.5 14q0-1.05.725-1.775Q7.95 11.5 9 11.5q1.05 0 1.775.725.725.725.725 1.775 0 1.05-.725 1.775Q10.05 16.5 9 16.5ZM5 22q-.825 0-1.413-.587Q3 20.825 3 20V6q0-.825.587-1.412Q4.175 4 5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588Q21 5.175 21 6v14q0 .825-.587 1.413Q19.825 22 19 22Zm0-2h14V10H5v10ZM5 8h14V6H5Zm0 0V6v2Z"/></svg>
const fwdSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="#888" height="24" width="24"><path d="M9.4 18 8 16.6l4.6-4.6L8 7.4 9.4 6l6 6Z"/></svg>;
const bckSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="#888" height="24" width="24"><path d="m14 18-6-6 6-6 1.4 1.4-4.6 4.6 4.6 4.6Z"/></svg>;
const colapseSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="#888" height="24" width="24"><path d="m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z"/></svg>;
const unColapseSvg = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z"/></svg>

export const KwFormInput = (
{ type='text',
placeholder="",
multiline=false,
value,
name,
rows=3,
label='',
fullwidth=false,
labelBackground='#fff',
borderColor="#e0e0e0",
highlight = '#222',
onChange=(e)=>{},
resizable=false,
labelColor = '#666',
unFocus = (e) => {},
onBlur = (e) => {},
prefix,
suffix,
fontSize = 16
}

) => {
    const labelRef = useRef(null);
    const inputRef = useRef(null);
    const containerRef = useRef(null);
  
   

    useEffect(() => {
       
        if(inputRef.current && multiline) {
            new ResizeObserver(resizeMulti).observe(inputRef.current);
        }

    }, []);

    useEffect(() => {
        if(value && value.trim() !== '') {
            inputValueLoaded();
        }
    }, [value])

    const inputFocusOut = (e) => {
        if(inputRef.current.value === '') {
            inputRef.current.style.borderColor = borderColor;
            labelRef.current.style.top = '50%';
            labelRef.current.style.fontWeight = 'normal';
            labelRef.current.style.marginTop = '-10px';
            labelRef.current.style.color=labelColor;
            labelRef.current.style.fontSize = '14px';
            labelRef.current.style.backgroundColor = 'transparent';
            containerRef.current.style.borderColor = 'transparent';

        } else {
            labelRef.current.style.color=labelColor;
            containerRef.current.style.borderColor = 'transparent';
            inputRef.current.style.borderColor = borderColor;
        }
        unFocus(e);
        onBlur(e);
    }

    const inputClicked = (e) => {
       // e.stopPropagation();
        containerRef.current.style.borderColor = highlight;
        inputRef.current.style.borderColor = 'transparent';
        inputRef.current.focus();
        const labelElem = labelRef.current;
        labelElem.style.fontSize = '12px';
        labelElem.style.transition = 'all .2s';
        labelRef.current.style.fontWeight = '500';
        labelElem.style.top = '-10px';
        labelRef.current.style.color=highlight;
        labelRef.current.style.marginTop = '0';
        labelElem.style.backgroundColor = labelBackground;
    }

    const inputValueLoaded = useCallback(() => {
        if(labelRef && containerRef) {
            containerRef.current.style.position = 'relative';
            labelRef.current.style.transition = 'none';
            containerRef.current.style.display = 'inline-block';
            labelRef.current.style.top = '-8px';
            labelRef.current.style.fontWeight = '500';
            labelRef.current.style.fontSize = '12px';
            labelRef.current.style.marginTop = '0';
            labelRef.current.style.backgroundColor = labelBackground;
        }
    }, [value])
    
   

    const resizeMulti = () => {
        console.log(inputRef.current.offsetWidth);
        containerRef.current.style.width = (inputRef.current.offsetWidth+4)+'px';
        containerRef.current.style.height = (inputRef.current.offsetHeight+4)+'px';
    }
    return (
        <div ref={containerRef} onClick={inputClicked} style={{position:"relative", borderRadius: 8, border: '2px solid transparent', display: fullwidth ? 'inline-block': 'block', boxSizing: 'border-box', width: fullwidth ? '100%' : '240px'}} className="form-field">
            <p ref={labelRef} style={{position: "absolute", left: prefix ? 24 : 16, height: 18, top: '50%', margin: 0, marginTop: -10, padding: '0 4px', fontSize: 14, transition: 'all .2s', color:labelColor}} className="label">{label}</p>
        {prefix ? <div style={{position:'absolute', height: 48, width: 24, display: 'flex', alignItems: "center", left: 8, top: -1}}>{prefix}</div> : null}
        {multiline ? 
        <textarea  id="inputmulti"  ref={inputRef} rows={rows} value={value}  name={name} style={{padding: '12px 16px', borderRadius: 8, border: `1px solid ${borderColor}`, resize: resizable ? "both" : "none" , outline:"none", width: '100%', boxSizing: "border-box", fontSize}}
        onChange={onChange} 
         onBlur={inputFocusOut}
        />:
        <input ref={inputRef} style={{padding: '12px 16px', paddingLeft: prefix ? 24 : 16, borderRadius: 8, border: `1px solid ${borderColor}`, height: 48, outline:"none", width: '100%', boxSizing: "border-box", fontSize}} 
         value={value}
         name={name}
         onChange={onChange} 
         onBlur={inputFocusOut} />
        }
        {suffix ? <div onClick={(e) => {e.preventDefault(); e.stopPropagation(); }} style={{position:'absolute', height: 48, widows: 24, display: 'flex', alignItems: "center", right: 8, top: -1}}>{suffix}</div> : null}
        </div>
    )
   
}


export const DateField = ({
    fullWidth = false,
    label = 'Choose a date',
    paper
}) => {
    const today =  new Date();
    const [dateSettings, setDateSettings] =  useState({year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDay(), numdays: new Date(today.getFullYear(), today.getMonth()+1, 0).getDate()});
    const [selected, setSelected] = useState(null);
    const [monthDays, setMonthDays] =  useState([]);
    const [years, setYears] = useState([]);
    const [view, setView] = useState('d')
    const [selectedval, setSelectedVal] =  useState('');
    const dateInputRef = useRef(null);
    const ddRef = useRef(null);
    const [isshowing, setIsShowing] = useState(false);
   
   
    

    const months = ['Janurary', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const styles = {
       lisItem: {display: "flex", alignItems: 'center', justifyContent: 'center', width: 48, height: 48}
    }
    useEffect(()=>{
        createMonth();
        window.addEventListener('click', (e) => {
            setIsShowing(false);
            setView('d');
        });
    },[])

    useEffect(() => {
       
        createMonth();

    }, [dateSettings])

    useEffect(() => {
        formatSelectedValue();
    }, [selected])

    useEffect(() => {
        if(!isshowing) {
            const year =  selected ? selected.getFullYear() : today.getFullYear();
            const month = selected ? selected.getMonth(): today.getMonth();
            const day =  selected ? selected.getDate() : today.getDate();
            setDateSettings(ds => ({...ds, year, month, day}));
        }
    }, [isshowing])

    

    const createYearsArr = useCallback((action, nw=false) => {
        let res = [];
        
        let i =0;
        let start 
        if(action == 'a') {
           start = years.length !== 0  &&  !nw ? years[3][3]+1 : selected ? selected.getFullYear() - 4 : today.getFullYear() -4;
           
        } else {
            start = years.length !== 0 && !nw ? years[0][0] - 16 : selected ? selected.getFullYear() -4 : today.getFullYear(-4);
        }

        let curr = start;
        let row = [];
        while (i < 16) {
            row.push(curr++);
            if(row.length === 4) {
                res.push(row);
                row = [];
            }
            i++;

        }
       
        return res;
        
    });

  

    const setYearView =(e) => {
        e.preventDefault();
        setYears(createYearsArr('a', true));
        setView('y');

    }


    const changeYears = (a) => {
        setYears(createYearsArr(a));
    }

    const YearSelected = (y) => {
        setDateSettings(d => ({...d, year: y}));
        setYears([]);
        setView('m');
    }


    const monthSelected = (m) => {
        
        setDateSettings(d => ({...d, month: m, numdays: new Date(d.year, m+1, 0).getDate()}));
        setView('d');

    }

    const daySelected = (dt) => {
        if(dt === -1) {
            return;
        }
        
        setDateSettings(d => ({...d, day: dt}))
        const sdate  = new Date(dateSettings.year, dateSettings.month, dt,0,0,0);
        setSelected(sdate);
        setIsShowing(false);
       

    }

    const getDayBg = (d) => {
       
        if(selected && selected.getFullYear() === dateSettings.year && selected.getMonth() === dateSettings.month && selected.getDate() === d) {
            return 'teal';
        } else if(today.getFullYear() === dateSettings.year && today.getMonth() === dateSettings.month && today.getDate() === d) {
            return 'rgba(0,128,128,0.5)';
        } else {
            return 'transparent';
        }
        

    }

    const formatSelectedValue = () => {
        let dt = ''
        if(selected) {
            const m = selected.getMonth()+1 < 10  ? `0${selected.getMonth()+1}`:selected.getMonth()+1;
            const d = selected.getDate() < 10  ? `0${selected.getDate()}`:selected.getDate();
            dt =  `${d}/${m}/${selected.getFullYear()}`;
        } else {
            dt = '';
        }
        setSelectedVal(dt);
    }

    const parseManualInput = (e) => {
        const val  = e.target.value;
        const dateparts = val.split('/');
        console.log(val);
        dateInputRef.value = val;
        if(dateparts.length !== 3) {
            return;
        }
        const day =  parseFloat(dateparts[0]);
        const month = parseFloat(dateparts[1]);
        const year = parseFloat(dateparts[2]);
        if(isNaN(day) || isNaN(month) || isNaN(year) || day < 1 || day  > 31 || month < 1 && month > 12 || year < 1 ) {
            console.log('Wrong')
            return;
        }
        let date;
        try {
            date = new Date(year, month-1, day, 0, 0, 0);
            
            console.log(date);
        } catch (ex) {
            console.log(ex);
        }
        if(date && !isNaN(date)) {
            setDateSettings(d => ({...d, year, month:month-1, day}));
            setSelected(date);


        }
    }

    const monthArrowClicked = (a) => {
        if (a === 'u') {
            if(dateSettings.month < 11) {
                setDateSettings(d => ({...d, month: d.month+1}));
            }  else {
                setDateSettings(d => ({...d, month: 0}));
            } 
            } else {
                
                if(dateSettings.month > 0) {
                setDateSettings(d => ({...d, month: d.month-1}));
            
            } else {
                setDateSettings(d => ({...d, month: 11}));
            }
        }
    }
    


    const createMonth = useCallback(() => {
        const curday =   new Date(dateSettings.year, dateSettings.month, 1).getDay();
        console.log(curday);
       
        
        const md =  [];
        let dy = 1
        let weekdays = [];
        if(curday != 0) {
            let prevmntdays = new Date(dateSettings.year, dateSettings.month, 0).getDate();
            console.log(prevmntdays, curday)
        for (let wd =0; wd < curday; wd++) {
            weekdays.push(-1);
            prevmntdays ++;
        }
      }
       let i = 0;
       console.log(i,dateSettings.numdays);
        while (i < dateSettings.numdays) {
            console.log(i, dy);
            weekdays.push(dy++);
            if(weekdays.length === 7 || i === dateSettings.numdays-1) {
                md.push(weekdays);
                weekdays = [];
            } 
          
            i++;
        }
        if(md[md.length-1].length !== 7) {
          
            for (let ed = md[md.length-1].length; ed <7; ed++) {
                md[md.length-1].push(-1);
            }
        }
        console.log(md);
        setMonthDays(md);
    }, [dateSettings]);

    let cal;
    if(view === 'd')
     {
         cal =  <div style={{padding: 8}}>
         <div style={{padding: '12px 16px', display: "flex"}}>
            <button onClick={setYearView}>{months[dateSettings.month]} {dateSettings.year}</button>
            <div style={{flex: '1 1 auto'}}></div>
            <div>
                <button className="kw-action-button" onClick={(e) => {e.preventDefault(); e.stopPropagation(); monthArrowClicked ('d')}}>{bckSvg}</button>
                <button className="kw-action-button"  onClick={(e) => {e.preventDefault(); e.stopPropagation(); monthArrowClicked ('u')}}>{fwdSvg}</button>
            </div>
         </div>
          <ul style={{display: 'flex', listStyle: 'none', margin: 0, padding: 0, justifyContent: "space-evenly"}}>
                  <li style={styles.lisItem}>Su</li>
                  <li  style={styles.lisItem}>Mo</li>
                  <li  style={styles.lisItem}>Tu</li>
                  <li  style={styles.lisItem}>We</li>
                  <li  style={styles.lisItem}>Th</li>
                  <li  style={styles.lisItem}>Fr</li>
                  <li  style={styles.lisItem}>Sa</li>
              </ul>
          {
             
              monthDays.map((w, i) => (
                 
                      <ul style={{display: 'flex', listStyle: 'none', margin: 0, padding: 0, justifyContent: "space-evenly"}}  key={i}>
                          {w.map((d,di) => (
                          <li className={d !== -1 ? 'date-field-date' : ''} key={ (i * 7)+ di} style={{width: 36, height: 36, display: "flex", alignItems: 'center', justifyContent: 'center', cursor: 'pointer', backgroundColor: getDayBg(d), borderRadius: '50%'}}
                          onClick={() => {
                              daySelected(d);
                          }}
                          >{d !== -1 ? d :''}</li>) )}
                      </ul>
                 
              ))
          }
          </div>
     } else if (view === 'y') {
        
        cal =<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start",  padding: 8}}>
        <div style={{display: 'flex', padding: '12px 16px', width: '100%', boxSizing: 'border-box'}}>
            {years.length > 0 ? <div><button style={{display: 'flex', alignItems: 'center'}} onClick={(e)=> {e.stopPropagation(); e.preventDefault(); setView('d')}}>{years[0][0]} - {years[3][3]} <span style={{alignSelf: 'flex-end'}}>{colapseSvg}</span></button></div> : null}
            <div style={{flex: '1 1 auto'}}></div>
            <div>
            <button className="kw-action-button" onClick={(e) => {e.preventDefault(); e.stopPropagation(); setYears(createYearsArr('s'))}}>{bckSvg}</button>
             <button className="kw-action-button"  onClick={(e) => {e.preventDefault(); e.stopPropagation(); setYears(createYearsArr('a'))}}>{fwdSvg}</button>
            </div>
            </div>
         {years.map((r,i) => (
             <ul style={{display: 'grid', listStyle: 'none', margin: 0, padding: 0, gridTemplateColumns: 'repeat(4, 1fr)', margin: 0, padding: 0, width: '100%'}} key={i}>
                 {r.map(y => (<li style={{textAlign:'center', cursor: 'pointer', height: 48, display: 'flex', alignItems: "center", justifyContent:'center', backgroundColor: (selected && selected.getFullYear() === y) || (!selected && today.getFullYear() === y)  ? 'teal' : 'transparent', borderRadius: 25}} key={y} onClick={
                     () => YearSelected(y)
                 } className="date-field-date">{y}</li>))}
             </ul>
         ))}
     </div>
     } else if (view === 'm') {
         cal =  <div  style={{ display: "flex", flexDirection: "column", alignItems: "flex-start",padding: 8, paddingBottom: 16}}>
         <div style={{display: "flex", alignItems: 'center', width: '100%', padding: '12px 16px', boxSizing: 'border-box'}}><button style={{display: 'flex', alignItems:'center'}} onClick={(e) => {e.preventDefault(); setView('d')}}><span>{dateSettings.year}</span><span>{colapseSvg}</span></button>
         <span style={{flex: '1 1 auto'}}></span>
         <button className="kw-action-button" onClick={(e) => {e.preventDefault(); e.stopPropagation(); setDateSettings(yc => ({...yc, year: yc.year+1}))}}>{bckSvg}</button>
         <button className="kw-action-button"  onClick={(e) => {e.preventDefault(); e.stopPropagation(); setDateSettings(yc => ({...yc, year: yc.year+1}))}}>{fwdSvg}</button>
           
         </div>
         <div style={{padding: '12px 16px'}}>{dateSettings.year}</div>
         {[months.slice(0,4), months.slice(4,8), months.slice(8)].map((r, ri) => (
             <ul  style={{display: 'grid', listStyle: 'none', margin: 0, padding: 0, gridTemplateColumns: 'repeat(4, 1fr)', margin: 0, padding: 0, width: '100%'}}>
                 {r.map((m, i) => (<li key={m} style={{textAlign:'center', cursor: 'pointer', height: 48, display: 'flex', alignItems: "center", justifyContent:'center', backgroundColor: (selected && selected.getMonth() === ((ri * 4) + i)) || (!selected && today.getMonth() === ((ri * 4) + i))  ? 'teal' : 'transparent', borderRadius: 25}}
                   onClick={
                     () => monthSelected((ri * 4) + i)
                 }
                   className="date-field-date">{m.substring(0,3).toUpperCase()}</li>))}
             </ul>
         ))}
     </div>
     } else {
         cal = null;
     }
    return (
           <div className="kw-datepicker" onClick={(e) => e.stopPropagation()} style={{ position: 'relative', width: fullWidth ? '100%': 240, display: fullWidth ? 'block' : 'inline-block'}}>
           
           <KwFormInput fontSize={16} label={label}  onBlur={parseManualInput} onChange={(e) => setSelectedVal(e.target.value)} value={selectedval} fullwidth={fullWidth} suffix={<div style={{cursor: 'pointer'}} onClick= {(e) => {e.stopPropagation(); console.log('we display'); setIsShowing(true)}}>{ddSvg}</div>} />
        
            <div ref={ddRef} style={{position: 'absolute', paddingTop:16, width: 296, height: 354, top: -4, zIndex: 99, border: '1px solid #e0e0e0', backgroundColor: '#fefefe', display: isshowing ? 'block': 'none',  animation: 'swiftin .2s',  boxShadow: paper ? '0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f': 'none', borderRadius: 5}}>
               {cal}
            </div>
           </div>  
    )
}


export const  DropDown = ({
selectBackground,
 label='', 
 prefix,
 rounded = false,
 type="outline", 
 items=[], 
 menuColor="#fefefe",
 displayField,
 highlight="#e0e0e0",
 labelColor = '#666',
 fullWidth = false,
 border = '#e0e0e0',
 paper,
 color = '#222',
 required,
 name,
 valueField,
 menuItemTextColor= '#fefefe',
 value,
 fontSize = 16,
 onChange = (e) => {}
}) => {
    const [currinde, setCurrindex] = useState(-1);
    const [shown, setShown] = useState(false);
    const inpRef =  useRef(null);
    const ddRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const contRef =  useRef(null)

    useEffect(() => {
        if(ddRef.current && shown) {
            console.log('There is focus')
            ddRef.current.focus();
        }
    
    },[ddRef.current, shown])






    const getLabelLeft = () => {
        if(prefix) {
            if(rounded) {
                return 36;
            } else {
                return 24
            }
        } else {
            if(rounded) {
                return 24
            } else{
                return 12
            }
        }
    }

    const getLAbelTop = () => {
        if(type === 'outline') {
            return -8
        } else {
            return 8
        }
    }

    const [labelstyle, setLabelStyle] = useState({margin: 0, position: 'absolute', left: getLabelLeft(), top: '50%', marginTop: type === 'flat'? -16 : -20, height: 24, lineHeight: '24px', padding: type ==='flat' ? 0 : '0 4px', backgroundColor: type === 'flat' ? 'transparent' : selectBackground, color: labelColor, fontSize: 14})

    useEffect(() => {
    
        if(value) {
            if(displayField) {
                const ind = valueField ? items.findIndex(v => v[valueField] === value) : items.indexOf(value);
                console.log(ind);
                if(ind !== -1) {
                    setSelectedItem(items[ind]);
                }
            } else {
                const ind = items.indexOf(value);
                setSelectedItem(items[ind]);

            }

            setLabelStyle({...labelstyle, top: getLAbelTop(), fontSize: 12, fontWeight: 500})
            
        }
    }, [value])
    
    const gotFocus = () => {
        console.log('focus given');
        setLabelStyle({...labelstyle, top: getLAbelTop(), fontSize: 12, fontWeight: 500});
        inpRef.current.focus();
        setShown(true);
        
        
    }

    const lostFocus = (e) => {
    
        if(selectedItem) {
            setLabelStyle({...labelstyle, top: getLAbelTop(), fontSize: 12});
        } else {
            setLabelStyle({...labelstyle, top: getLAbelTop(), top: '50%', marginTop: type === 'flat' ? -16 : -20, fontSize: 14, fontWeight: 'normal'})
        }
        setShown(false);

    }

    const arrowPressed = (e)=> {
    
        e.preventDefault();
        e.stopPropagation();
       
        const keyCode =  e.which;
        console.log(keyCode);
        const arrow = {up: 38, down: 40, enter: 13};
        switch (e.keyCode) {
            case arrow.up:
                setCurrindex(ci => ci > 0 ? ci-1 : items.length -1);
                ddRef.current.scrollBy({ top: -30, behavior: 'smooth' })
                break;
            case arrow.down:
                console.log('down');
                setCurrindex(ci => ci < items.length -1 ? ci +1 : 0);
                ddRef.current.scrollBy({ top: 30, behavior: 'smooth' })
                break;
            case arrow.enter:
                console.log('enter');
                itemSelected(e, items[currinde]);
                break;
            default: break;

        } 

    }

    const itemSelected = (e,v) => {
        e.stopPropagation();
        setShown(false);
        setSelectedItem(v);
        onChange(v);
      

    }

    console.log(shown, currinde);

    return <div ref={contRef} style={{width: fullWidth ? '100%': 240, display: fullWidth ? 'block': 'inline-block', position: 'relative', border: type === 'flat' ? '1px solid transparent' : `1px solid ${border}`, borderRadius: rounded ? 25 : 8, padding: type === 'flat' ? 0 : 1, maxHeight: 48}}>
        <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            gotFocus()
        }} 
       
         style={{backgroundColor:  type === 'flat' ?  selectBackground || '#ddd' : selectBackground|| '#fefefe', height: type === 'flat'  ? 48 : 34, position: "relative", borderTop: '14px solid transparent', cursor: 'pointer', borderRadius: rounded ? 24 : 5}}>
            <p style={labelstyle}>{label}</p>
            <input required={required} type="hidden" onKeyDown={arrowPressed} ref={inpRef} />
            <div style={ type === 'flat' ? {padding: rounded ? '12px 24px 0px 24px' :'12px 12px 0 12px', height: 48, color: color, fontSize: fontSize} : {padding: rounded ? '0 24px 0px 24px' :'0px 12px 0 12px' ,position: 'absolute', top: -12, fontSize: fontSize , height: 46, lineHeight: '46px', color: color} }>{selectedItem ? displayField  ? selectedItem[displayField]: selectedItem : ''}</div>
            <div onClick={() => {setShown(true)}} style={{position: 'absolute', right: rounded ? 24 : 8, top: '50%', marginTop: -18, height: 24, cursor: 'pointer'}}>{unColapseSvg}</div>
           {shown ?
            <ul  ref={ddRef} onBlur = {lostFocus} tabIndex = {0} role="button" onKeyDown={arrowPressed}  style={{listStyle: 'none', margin: 0, padding: 0, width: '100%', backgroundColor: menuColor, position: "absolute", top: -16, border: '1px solid #e0e0e0',  animation: 'heightin .1s', outline: 'none',  boxShadow: paper ? '0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f': 'none', borderRadius: 5, zIndex: 9999, overflow: 'auto'}}>
                {items.map((i,ind) => (<li onClick={(e) => itemSelected(e, i)} key={ind} style={{padding: '12px 16px', backgroundColor: currinde === ind ? '#eee': menuColor, color: menuItemTextColor}}>{displayField ? i[displayField]:i}</li>))}
            </ul> 
            : null }
        </div>

    </div>
}

export const AutoComplete = ({
    label = '',
    labelColor='#666',
    fullWidth =  false,
    type = 'outline',
    rounded = false,
    borderColor = '#e0e0e0',
    prefix,
    items = [],
    displayField,
    onSearch =  (v) => {},
    onChange = (v) => {},
    paper,
    fontSize = 16,
    highlight = '#e0e0e0'

}) => {

    const [selectedItem, setSelectedItem] = useState(null);
    const [currindex, setCurrindex] =  useState(-1);
    const [inpval, setInpval] = useState('');
    const inpRef = useRef(null);
    const ddRef = useRef(null);
    const contRef =  useRef(null);
    const [fc, setFc] =  useState(false);

    const getLabelLeft = () => {
        if(prefix) {
            if(rounded) {
                return 36;
            } else {   alert('ouc');
                return 24
            }
        } else {
            if(rounded) {
                return 24
            } else{
                return 12
            }
        }
    }

    const getLAbelTop = () => {
        if(type === 'outline') {
            return -5
        } else {
            return 8
        }
    }

    const [labelstyle, setLabelStyle] = useState({
        fontSize: 14, 
        margin: 0,
        padding: 0, 
        height: 24, 
        position: 'absolute',
        zIndex: 999,
        top: '50%',
        backgroundColor: type === 'outline' ? '#fefefe' : 'transparent',
        padding: '0 4px',
        marginTop: '-16px',
        left: getLabelLeft(),
        transition: 'all .2s'
    })

    const [shown, setShown] = useState(false);

     
    const gotFocus = (e) => {
        contRef.current.style.borderColor=highlight;
        inpRef.current.style.borderColor = 'transparent';
        setLabelStyle({...labelstyle, top: getLAbelTop(), fontSize: 12, fontWeight: 500, marginTop: -16});
        inpRef.current.focus();
        setShown(true);
        
        
    }

    const lostFocus = (e) => {
        contRef.current.style.borderColor='transparent';
        inpRef.current.style.borderColor = borderColor;
        if(inpval === '') {
         if(selectedItem) {
            setLabelStyle({...labelstyle, top: getLAbelTop(), fontSize: 12});
        } else {
            setLabelStyle({...labelstyle,  top: '50%', marginTop: type === 'flat' ? -16 : -16, fontSize: 14, fontWeight: 'normal'})
        }
        }
        setShown(false);

    }

    const inputBlur = (e) => {
        console.log(fc);
        if (!fc) {
            setShown(false);
            lostFocus();
        }
    //   setShown(false);
    }

    console.log(shown)

    const arrowPressed = (e)=> {
    
       // e.preventDefault();
        const keyCode =  e.which;
        console.log(keyCode);
        const arrow = {up: 38, down: 40, enter: 13};
        switch (e.keyCode) {
            case arrow.up:
                setCurrindex(ci => ci > 0 ? ci-1 : items.length -1);
                break;
            case arrow.down:
                console.log('down');
                setCurrindex(ci => ci < items.length -1 ? ci +1 : 0);
                break;
            case arrow.enter:
                console.log('enter');
                itemSelected(e, items[currindex]);
                break;
            default:
                break;

        } 

    }

    const inpValueChanged = (e) => {
        const val  =  e.target.value;
        if(currindex !== -1) {
            setCurrindex(-1);
        }
        console.log('setting true');
        setShown(true);
        console.log(val);
        setInpval(val);
        onSearch(val);
    }

    const itemSelected = (e, v) => {
        e.stopPropagation();
        setInpval(displayField ? v[displayField] : v);
       
        setSelectedItem(v);
        onChange(v);
        lostFocus();
        setShown(false);
        
    } 

    return (
        <div
        onClick={gotFocus}
        ref={contRef}
         style={{position: 'relative', width: fullWidth ? '100%' : 240, height: type === 'flat' ? 64 : 48, border: type ==='outline' ? `2px solid transparent`: 'none', borderRadius: rounded ? 25 : 5, padding: 0, backgroundColor: type === 'flat' ? 'ddd': '#fefefe'}}>
             <div onKeyDown={arrowPressed}  style={{position: 'relative', borderTop: '12px solid transparent', width: '100%', height: type === 'flat' ? 50 : 34}}>
                <p style={labelstyle}>{label}</p>
                <input onClick={e => {setFc(false); setCurrindex(-1)}}  ref = {inpRef} onBlur={inputBlur} type="text"
                 style={{width: '100%', height: 62, position: 'absolute', top: -12, border: type ==='outline' ? `1px solid ${borderColor}` : 'none',borderRadius: rounded ? 25: 5, outline: 'none', padding: type === 'flat' ? '12px 16px 0 16px':'0 16px', boxSizing: 'border-box', fontSize: fontSize, backgroundColor: type === 'flat' ? '#ddd' : 'transparent'}} value={inpval}  onChange={inpValueChanged} />
            </div>
            {shown && items.length > 0 ?
               <ul ref={ddRef}  tabIndex={0}   style={{listStyle: 'none', margin: 0, padding: 0, borderRadius: 5, border: '1px solid #e0e0e0', transition:'height .2s', backgroundColor: '#fefefe', animation: 'heightin .1s', overflow: 'auto', boxShadow: paper ? '0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f': 'none'}} 
                onMouseOver = {e => setFc(true)}
                onKeyDown={arrowPressed} 
                onMouseLeave = {e => setFc(false)}
                onBlur={lostFocus}
            >
                {items.map((it, ind) => (
                    <li className="kw-li" 
                    onMouseOver={e => setCurrindex(ind)}
                    onClick={
                        (e) => itemSelected(e, it)
                    } key={ind} style={{padding: '12px 16px', backgroundColor: currindex === ind ? '#eee' : 'transparent'}}>{displayField ? it[displayField]: it}</li>
                ))}
            </ul> : null }
        </div>
    )

}
import { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { useStore} from "../contexts/app-context";
import AuthService from "../services/auth-service";
import { UserService } from "../services/rest";

const Home = () => {
    const [store, dispatch] = useStore();
    const [pageState, setPageState] =  useState({login: false, hide: true, driver: false});
    useEffect( () => {
        const islogin = AuthService.UserLoggedIn();
       
        if(islogin) {
        UserService.GetUser(AuthService.AuthDetails().uid).then(r => {
          
            if(r._id) {
                setPageState({login: islogin, hide: false, driver: r.driverid ? true : false})
                dispatch({type: 'update-user', payload: {firstname: r.firstname, lastname: r.lastname}})

            }
        });
    } else {
        setPageState({login: islogin, hide: false, driver: false})
    }
       
      
    }
    );


  
    //alert(store.driverid);

    return  !pageState.hide ? (
        <div className="home-paoge">
         {
            pageState.login && <>
               {pageState.driver && <> <Navigate to="/driver" /> </>}
               {!pageState.driver && <> <Navigate to="/ride" /> </> }
            </>
         }
          {!pageState.login &&  <Navigate to="/home" />}
        </div> 
    ) : null;
}

export default Home;
import { accent, primaryText } from '../colors';
import '../styles/rider-login.css';
import FormInput from '../components/form-input';
import { useCallback, useState } from 'react';
import { UserService } from '../services/rest';
import AuthService from '../services/auth-service';
import {Link, useNavigate} from 'react-router-dom';
import { useStore } from '../contexts/app-context';
import { useWsock } from '../contexts/Wsock';
const DriverLogin = () => {
    const [store, dispatch] =  useStore();
    const [login, setLogin] = useState({email: '', password: ''});
    const [message, setMessage] = useState(null);
    const wsock = useWsock();
    const nav = useNavigate();
    const SubmitLogin = (e) => {
        e.preventDefault();
        console.log(login);
        LoginUSer();

    }

    const LoginUSer  = useCallback(() => {
        setMessage(null);
        UserService.LoginUser(login).then(res => {
            console.log(res);
            wsock.emit('auth-message', {userid: res.uid});
            dispatch({type: 'update-user', payload: res});
            if(res.driverid) {
                dispatch({type: 'update-driverid', payload: res.driverid});
            }
            AuthService.SetAuthDetails(res);
            nav('/', {replace: true});
        }).catch(ex => {
            console.log(ex);
            setMessage('Could not log you in. Please try again')
        }) ;

    }, []);

    return (
        <div className="rider-login">
            <div className="login-form">
                <h1 className="title-medium">Sign in to drive</h1>
                <form style={{}}>
                <div style={{textAlign: 'center'}}>
                    <FormInput onChange={(e) => {
                        setLogin(l => ({...l, email: e.target.value}))
                    }} value={login.username} textAlign="center" label="Email or telephone" highlight={accent} color={primaryText} labelColor="#555" fullwidth />
                </div>
                <div>
                    <FormInput onChange={(e) => {
                        setLogin(l => ({...l, password: e.target.value}))
                    }} 
                    textAlign = "center"
                    value={login.password} type="password" label="Password" labelColor='#555' highlight={accent} color={primaryText} fullwidth />
                </div>
                <div  className="app-buttons">
                    <button style={{width: '100%', margin: 0}} onClick={SubmitLogin} className="accent">Login</button>
                </div>
                {message ? <p style={{fontSize: '.8rem'}}>{message}</p> : null}
                <div style={{marginTop: 32}}>
                <p style={{textAlign: 'left', fontSize: '.9rem'}}>Dont have an account? <Link to="/register-driver"><span style={{color: accent}}>Sign up to drive</span></Link></p>
                <p style={{color:accent, textAlign: "left", fontSize: '.9rem'}}><Link to="/forget-password">Forgot your password?</Link></p>
                </div>
                </form>
            </div>

        </div>
    )
}

export default DriverLogin
import { useCallback, useEffect, useState } from "react";
import DriverDetails from "../components/driver-details";
import FormInput from "../components/form-input";
import PhotoSelect from "../components/photo-select";
import VehicleDetails from "../components/vehicle-details";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { DriverService, UserService } from "../services/rest";
import Spinner from "../components/spinner";
import Alert from "../components/alert";
import { Strings } from "../settings";

const DriverProfile = () => {
    const [store, dispatch] = useStore();
    const [theme, themeDispatch] = useTheme();
    const [pState, setPstate] = useState({spin: false, alert: {type: null, text: null, visible: false}});
    const [driverPhoto, setDriverPhoto] =  useState(null);
    const [regStatus, setRegStatus]= useState(null);
    
    useEffect(() => {
        themeDispatch({type: 'toolbar', value: 'wide-static'});
    }, [])

    const ClearAlerts = () => {
        setPstate(s => ({...s, alert: {type: 'info', visible: false, text: ''}}));
    }


    const SavePhoto = (data) => {
        setPstate(p => ({...p, spin: true}))
        const formData =  new FormData();
        formData.append('file', data);
       // setSpin(true);
       
        UserService.SavePhoto(store.user.uid, formData).then(r => {
            setDriverPhoto(URL.createObjectURL(data));
            
        }).catch(ex => {
            console.log(ex);
            setPstate(p => ({...p, spin: true, alert: {type: 'error', text: ex.message, visible: true}}))
            setTimeout(ClearAlerts, 8000)
        }).finally(() => {
            setPstate(p => ({...p, spin: false}))
        });

    }


    const GetUserDetails = useCallback(async () => {
        try {
       
       
          const res = await UserService.GetUser(store.user.uid);
          dispatch({type: 'update-user', payload: {firstname: res.firstname, lastname: res.lastname}});       
        if(res.photo) {
          setDriverPhoto(`${Strings.api}/user/profilepic/${res._id}`);
        }
        } catch (ex) {
            setPstate(p => ({...p, spin: false, alert: {type: 'error', text: 'Oops something went wrong', visible: true}}))
        } finally{
            
        }
          

    }, [store.user.uid]);

    useEffect(() => {
        if(store.user.uid) {
        GetUserDetails();
        }
    }, [GetUserDetails, store.user.uid]);


   


    return ( store.user?.uid ? <div className="content-body" style={{color: theme.onBackground, textAlign: "left", paddingTop: 64}}>
        <h1 style={{marginTop: 16, padding: '0 16px'}}>Driver Profile</h1>
        {pState.alert.visible && <Alert text={pState.alert.text} severity={pState.alert.type} />}
        <div style={{textAlign: 'center', width: 280, marginBottom: 16, display: "flex", alignItems: "center", color: theme.warning}}>
           
                <PhotoSelect photoTaken={SavePhoto} width={120} height={120} initialPhoto={driverPhoto} />
                {!regStatus && <div>Awaiting approval</div>}
           
        </div>
        <div className="profile-section" style={{backgroundColor: theme.surfaceVariant}}>
             <div className="profile-section-fields">
            <DriverDetails setStaus={s => setRegStatus(s)} />
            </div>
        </div>
        
        <div className="profile-section" style={{backgroundColor: theme.surfaceVariant}}>
            <div className="profile-section-fields">
               <VehicleDetails />
            </div>
        </div>
       
        {pState.spin && <div style={{height: 80, position: "absolute", top: '50%', left: '50%', marginLeft: -40, marginTop: -40}}><Spinner /></div>}
      
    </div> : <></>)
}

export default DriverProfile;
const AuthServiceFactory = () => {
    const UserLoggedIn = () => {
        const token = window.localStorage.getItem('vep-token');
        const uid = window.localStorage.getItem('uid');
        return token && uid ? true : false;
    }

    const SetAuthDetails = (details) => {

        const {token, uid} = details;
        window.localStorage.setItem('vep-token', token);
        window.localStorage.setItem('uid', uid);
     }

     const AuthDetails = () => {
        const token = window.localStorage.getItem('vep-token');
        const uid = window.localStorage.getItem('uid');
        return {token, uid};
     }


    return Object.freeze({
        UserLoggedIn,
        SetAuthDetails,
        AuthDetails
    });

}
const AuthService = AuthServiceFactory()
export default AuthService;
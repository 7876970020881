import AppMap from '../components/app-map';
import DriverRideList from '../components/driver-ride-list';
import '../styles/driver.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DriverService, RideService } from '../services/rest';
import { useStore } from '../contexts/app-context';
import Spinner from '../components/spinner';
import DriverRideInfo from '../components/ride-info-driver';
import ConfirmSelect from '../components/confirm-select';
import {useNavigate} from 'react-router-dom';
import LocationService, { GetCurrentLocation, gettCurrentLocationAsync, ToRad, watchPosition, watchPositionAsync } from '../services/location-service';
import { useWsock } from '../contexts/Wsock';
import ConfirmComplete from '../components/confirm-complete';
import { useTheme } from '../contexts/theme-context';
const DriverPage = () => {
    const [center, setCenter] =  useState([-61.3794, 15.3092]);
    const  [radius, setRadius] =  useState(10);
    const [rides, setRides] = useState([]);
    const [store, dispatch] =  useStore();
    const [spin, setSpin] =  useState(false);
    const signals = [];
    const [rideStatus, setRideStstus] = useState(0);
    const [sb, setSb] = useState(null);
    const[driver, setDriver] = useState(null);
    const [ridemarkers, setRidemarkers] =  useState([]);
    const [heading, setHeading] =  useState(90);
    const wsock =  useWsock();
    const rideMarkersRef =  useRef(ridemarkers);
    const nav =  useNavigate();
    const [theme, dispatchTheme] = useTheme();
    
    


    

    
    useEffect(() => {
        const control =  new AbortController();
        const signal = control.signal;
    if(store?.user?.uid) {
       DriverService.GetDriverByUid(store.user.uid).then(dr => {
       setDriver(dr);
       dispatch({type: 'update-driverid', payload : dr._id});
       if(dr.registrationstatus === 0) {
        dispatchTheme({type:'toolbar', value: 'static-wide'});
        dispatchTheme({type: 'toolbar-width', value: '100%'})
        nav('/driver-profile');
        return;
       }
       dispatchTheme({type:'toolbar', value: 'wide'})
       dispatchTheme({type: 'toolbar-width', value: 52})
       const ridesinrad =  RideService.ListRidesInRadius({center,radius, signal});
       const penfingride = RideService.GetPendingRideDriver(dr._id);
       const subs = [ridesinrad, penfingride]
    
       Promise.all(subs).then(res => {
        console.log(res);
            const r =  res[1];
            const rl =  res[0];
           
             if(r) {

                dispatch({type: 'update-ride', payload: r});
                // add function to get center then call;
                wsock.emit('position-updated', {coords: {lat: center[1], lng: center[1]}, heading, to: r.requestedby, userid: store.user.uid})
             }
            setRides(rl);
        }).catch(ex => {
            console.log(ex);
        });
        });
        }
        return () => {
            if(signal && !signal.aborted) {
                control.abort();
            }
        }
    }, [center, dispatch, radius, store.user]);



    const getMarkers = useCallback(() => {
        let res = [];
        res.push({lat: center[1], lon: center[0], title: 'you', icon: '/images/taxi-48.png', rotation: heading})
        if(store.ride.origin) {
            res.push({lat: store.ride.origin.lat, lon: store.ride.origin.lng, title: 'Pickup'});
        }
        if(store?.ride?.stops) {
            for (let stop of store.ride.stops) {
                res.push({lat: stop.lat, lon: stop.lng, icon: 'images/stop32.png', title: 'Stop'});
            }
        }


        return res;   
    }, [center, heading, store.ride])


    

    

    useEffect(() => {
        
        if(store.ride && store.ride._id) {
            const mks =  getMarkers();
            rideMarkersRef.current =  mks;
            setRidemarkers(mks);
          
            if (store?.ride?._id && rideStatus !== 1 && rideStatus !== 2 && sb !== 'SELCONFIRM' && sb !== 'DONECONFIRM') {
                
                setSb('RIDEINFO');
            } 


               
    
 
        } else {
            setSb('RIDELIST');
            setRidemarkers([]);
        }
    },[store.ride, rideStatus, sb, getMarkers]);


    const watchPos =  useCallback(() => {
        if(store.ride && store.ride._id) {
         const wid = watchPosition((coords) => {
         const mheading =  coords.heading;
        
         const rm =  [...rideMarkersRef.current];
         setHeading(mheading);
         const loc =  ridemarkers.findIndex(i => i.title === 'You');
         if(loc !== -1) {
             rm[loc] = {lat: center[1], lon: center[0], title: 'you', icon: '/images/taxi-48.png', rotation: mheading};
         }else {
         rm.push({lat: center[1], lon: center[0], title: 'you', icon: '/images/taxi-48.png', rotation: mheading});
         }
        
         rideMarkersRef.current =  rm;
         setRidemarkers(rm);
         RideService.GetRunningCost(store.ride._id, center).then(r => {
            console.log(r)
        
            dispatch({type: 'update-ride', payload: {runningcost: r.cost}});
        
         }).catch(ex => {
            
            console.log(ex);
            
         });

     }, (err) => {
         console.log(err);

     });
     return wid;
  }
 }, [store.ride, center])


    useEffect(() => {

       
        wsock.on('position-updated', (pos) => {
          
        });
    
       const wid =  watchPos();
       return () => {
        navigator.geolocation.clearWatch(wid);
       }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])










    

    

    

    const selectRide = () => {
        store.ride.driver = driver._id;
        RideService.SelectRIde({id: store.ride._id, data: store.ride}).then(r => {
            dispatch({type: 'update-ride', payload: r});
            setRideStstus(1);
            
        }).catch(ex => {
             console.log(ex);
        });
    }

    const cancelRide = () => {

        RideService.CancelPendingRide(store?.ride?._id, driver._id).then(r => {
            // dispatch({type: 'update-ride', payload: r});
            setSb('RIDELIST');
            setRideStstus(0);
            dispatch({type:'reset-ride'})
            RideService.ListRidesInRadius({radius,center}).then(l => setRides(l)).catch(ex =>{
                console.log(ex);
            })
        }).catch(ex => {
            console.log(ex);
        })

    }


    const doneClicked  = () => {
      
         RideService.GetRunningCost(store.ride._id, center).then(ccost => 
            {
        store.ride.runningcost = ccost.cost;
        setSb('DONECONFIRM')
        dispatch({type: 'update-ride', payload: {runningcost: ccost.cost}});
        alert('setting sb');
       
            }
        ).catch (ex => {
            alert('error');
            console.log(ex);
        })

    }


    const startRide = () => {
        RideService.StartRide(store?.ride?._id, driver._id).then(r => {
            dispatch({type: 'update-ride', payload: r});
        
        }).catch(ex => {
            console.log(ex);
        });
    }


    const positionChanged = (coords) => {
        console.log(coords);
        if(store.ride && store.ride._id) {

        const mi =  ridemarkers.findIndex(r => r.title === 'You');
        const tr = [...ridemarkers]
        if(mi !== -1) {
            ridemarkers.splice(mi,1);
        }
        tr.push({lat: center[1], lng: center[0], title: 'you', icon: '/images/taxi-48.png'});
        wsock.emit('position-updated', {coords: {lat: coords.latitude, lng: coords.longitude}, heading: coords.heading, to: store.ride.requestedby, userid: store.user.uid})
         setRidemarkers(tr)
    }
        
    }

    const completeRide =  async () => {
       try {
          //  const loc = await gettCurrentLocationAsync();
          //  console.log(loc);
           // const data = {stop: [loc.longitude, loc.latitude]}
           const data = {stop: [store.ride.stops[0].lng, store.ride.stops[0].lat]}

          
            const res = await RideService.CompleteRide(store.ride._id, data);
            
            nav('/ridelist', {replace: true, state: {driverid: driver._id}});
        } catch (ex) {
            console.log(ex);
        }
        
    }


    const infoActionCallback = (action) => {
        switch (action) {
            case 'LIST':
                if (rideStatus !== 1 && rideStatus !== 2) {
                     setSb('RIDELIST');
                     dispatch({type:'reset-ride'})
                }
                break;
            case 'SELECT':
                setSb('SELCONFIRM')
                break;
            case 'CANCEL':
                cancelRide();
                break;
            case 'START':
                startRide();
                break;
            case 'DONE':
                doneClicked();
                break;
            default:
                throw new Error('oops');
        }

    }


    const confirmCallback = (action) => {
        if(action === 'CANCEL') {
            setSb('RIDELIST');
            dispatch({type:'reset-ride'})
        } else {
            setSb('RIDEINFO');
            selectRide();
        }
        
    }

    const completeCallback = (action) => {
        if(action === 'CONFIRMDONE') {
            completeRide();
        } else {
            setSb('RIDEINFO');
        }

    }




    const calcDistance = (lon1, lat1, lon2, lat2) => {

        var R = 6371; // Radius of the earth in km
        var dLat = ToRad(lat2-lat1)  // Javascript functions in radians
        var dLon = ToRad(lon2-lon1)
        console.log(dLon, dLat)
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(ToRad(lat1)) * Math.cos(ToRad(lat2)) *  Math.sin(dLon/2) * Math.sin(dLon/2); 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        console.log(d);
        return d;
    }
    

    let info_screen = null;

    switch (sb) {
        case 'RIDEINFO':
            info_screen = <DriverRideInfo  ride={store?.ride} actionCallback={infoActionCallback} /> 
            break;
        case 'RIDELIST':
            info_screen = <DriverRideList rides={rides} fetching={setSpin} />
            break;
        case 'SELCONFIRM':
            info_screen = <ConfirmSelect actionCallback={confirmCallback} />
            break;
        case 'DONECONFIRM':
            info_screen = <ConfirmComplete actionCalback={completeCallback} />
            break;
        default:
            break;

    }
     

  

    return (driver ? <>
    <div className="fullscreen-map-with-toolbar">
       
        <AppMap gpx={store?.ride?.gpx} markers={ridemarkers} />
        
        {info_screen}
    </div>
    {spin ?<div style={{position: "absolute", top: "50%", left: "50%", marginLeft: -32, marginTop: -32}}><Spinner size={64} /></div>: null}
    
    </>: null)
}
export default DriverPage;
import { useEffect, useRef, useState } from "react"
import { useTheme } from "../contexts/theme-context";
import Debounce from "../services/debouncer";
import PlaceApi from "../services/place-api";

const PlaceSearch = ({onChange, value, color = '#e0e0e0', labelColor ='#a0a0a0 ', highlight = '#e0e0e0', fullwidth=false, border= "#e0e0e0", label='', background="#fefefe"}) => {
    
    const [places, setPlaces] =  useState([]);
    const [visible, setVisible] =  useState(false);
    const [place, setPlace] = useState(value);
    const placeRef = useRef(place);
    const inpRef = useRef(null);
    const [labelstyle, setLabelstyle] = useState({fontSize: '.8rem', position: "absolute",color: labelColor, top: "50%", marginTop: "-8px", left: 24, height: 16, pointerEvents: 'none', transition: 'all ease-out .2s'});
    const [outline, setOutline] = useState('none');
    const [theme] = useTheme();
    const [currindex, setCurrindex] = useState(-1);
    const ddRef = useRef(null);
  
    useEffect(() => {
        placeRef.current =  value;
       const listener =  window.addEventListener('click', () => {
      
           if(inpRef.current && !placeRef.current) {
              
              
               inpRef.current.value = '';
           }
           setVisible(false);

       });
       return () => {
           window.removeEventListener('click', listener);
       }
    }, []);

    useEffect(()=> {
        if(inpRef && value && value.shortname) {
            inpRef.current.value = value.shortname
            placeRef.current =  value;
            setPlace(value);
            inputFocus();
            onChange(value);
        }
    }, [value])

    useEffect(() => {
        if(places.length > 0) {
            setVisible(true);
        }
    }, [places]);

    

    const valueChanged = (e) => {
        const arrow = {38: 'UP', 40:'DOWN',  13:'ENTER' };
        
        if(arrow[e.keyCode]) {
            return false;
        }
        if(placeRef.current) {
            placeRef.current = null;
        }
        if(!(''||e.target.value.trim())) {
            return;
        }
        PlaceApi().SearchPlace(e.target.value).then(r => {
        
            setPlaces(r);
        }).catch(ex => {
            console.log(ex);
        });
    }

    const placeSelected =  p => {
        setPlace(p);
        if(onChange) {
            onChange(p);
        }
        placeRef.current =  p;
        setVisible(false);
        inpRef.current.value =  p.shortname;
    }

    const inputFocus = () => {
        const ls = {...labelstyle, fontSize: '.7rem', top: 8, marginTop: 0, left: 24, color: highlight}
        setOutline(`2px solid ${highlight}`);
        setLabelstyle(ls);
    }

    const loseFocus = (e) => {
        const {value} = e.target;
        if(!('' || value).trim()) {
           const ls = {...labelstyle, fontSize: '.85rem',  top: "50%", marginTop: "-8px", color: labelColor}
           setLabelstyle(ls);
        
        }
        //labelstyle.color = labelColor;
        setLabelstyle(ls => ({...ls,  color: labelColor}));
        setOutline('none');
    }

    const downPressed = (e) => {
        const arrow = { up: 38, down: 40, enter: 13 };
       if([arrow.up, arrow.down, arrow.enter].includes(e.keyCode)) {
        e.preventDefault();
       } 
        const keyCode = e.which;
        console.log(keyCode, e.keyCode, 'dhecking keycode');
        switch (e.keyCode) {
            case arrow.up:
                if(currindex === 0) {
                    ddRef.current.scrollBy({top: ddRef.current.offsetHeight, behavior: 'smooth'});

                } else {
                  
                     ddRef.current.scrollBy({ top: -60, behavior: 'smooth' })
                    
                }
                setCurrindex(ci => ci > 0 ? ci - 1 : places.length - 1);
                
                break;
            case arrow.down:
                if(currindex === places.length-1) {
                    ddRef.current.scrollTop = 0;
                } else {
                    if(currindex > 1) {
                        ddRef.current.scrollBy({ top: 60, behavior: 'smooth' })
                    }
                }
                setCurrindex(ci => ci < places.length - 1 ? ci + 1 : 0);
              
                break;
            case arrow.enter:
                placeSelected(places[currindex]);
                setVisible(false);
                break;
            default:
                return false;

        }

    }

    console.log(currindex);
  
    return (<div className="vp-form-field  drop-down" 
    style={{
        position: 'relative',
         width: fullwidth ? '100%' : 120,
         borderRadius:25
        
         }}
        >
        <label style={labelstyle}>{label}</label>
        <input onKeyDown={downPressed} ref={inpRef} onKeyUp={Debounce(valueChanged)} type="search" onFocus={inputFocus}  onBlur={loseFocus} style={{border: `1px solid ${border}`,  padding: '16px 24px 0 24px', height: 56, width: '100%', outline, transition:  'outline-width .2s ease-in', borderRadius: 8, backgroundColor: background, color }} />
        {visible ? <ul ref={ddRef} onKeyDown={downPressed} style={{backgroundColor: theme.secondaryContainer, color: theme.onSecondaryContainer, zIndex: 9999, borderColor: theme.outline, borderRadius: 8}} onClick={e => e.stopPropagation()} className="dd-list">
            {places.map((p,i) => (<li onClick={() => placeSelected(p)} key={p._id} style={{backgroundColor : i === currindex ? theme.neutral : theme.secondaryContainer, color: i === currindex ? theme.onSecondary: theme.onSecondaryContainer, borderColor: theme.outline }}>
                <p style={{margin: 0}}>{p.shortname}</p>
                <span style={{fontSize: '.8rem'}}>{p.placename}</span>
                </li>))}
        </ul> : null}
    </div>)
}

export default PlaceSearch
import React, { useEffect, useReducer, useState } from 'react';
import DriverReducer from '../services/driver-reducer';
import '../styles/register-driver.css';
import VisibilityIcon from '../icons/visibility';
import VisibilityOffIcon from '../icons/visibility-off';
import DriverValidator from '../services/driver-validatior';
import PlaceSearch from '../components/place-search';
import DriverApi from '../services/driver-api';
import FormInput from '../components/form-input';
import { useTheme } from '../contexts/theme-context';
import { KwButton } from '../components/buttons';

const RegisterDriver = () => {
    const driverinit = {
        registrationfirstname:'',
        registrationlastname: '',
        registrationemail: '', 
        registrationphone: '', 
        registrationpassword: '',
        location: ''
    };


  
    const [showpasword, setShowpassword] =  useState(false);
    const [driver, dispatchdriver] =  useReducer(DriverReducer, driverinit);
    const [theme, dispatchTheme] =  useTheme();
    const [pstate, setPstate] = useState({view: 'F'});
    const valueChanged = (e) => {
        const {name, value} = e.target;
        dispatchdriver({type: 'update', payload:{[name]: value}})
    }

    useEffect(() => {
        dispatchTheme({type: 'toolbar-width', value: '100%'})
    }, []);

    

    const toggleIcon = () => {
        setShowpassword(v => !v);
    }

    const placeSelected = (p) => {
        dispatchdriver({type: 'update', payload: {location: p}});
    }

    const preSave = async (e) => {
        try {
            e.preventDefault();
            const code =  await DriverApi().ValidateNewDriver(driver); 
            setPstate(p => ({...p, view: 'C'}));

        } catch (ex) {
            throw ex;
        }
    }

    const saveRegistration = async (e) => {
        if(!driver.code || driver.code.length !== 6) {
            return;
        }
       
        try {
       
        
            const res =  await DriverApi().RegisterDriver(driver);
            dispatchdriver({type: 'update', payload: res});
            setPstate(p => ({...p, view: 'S'}));
        } catch (ex) {
            console.log(ex);
        }
    }

   

    return (<>
    <div className="register-driver">
        <div className="space register-driver-left" style={{color: theme.onBackground, textAlign: 'left'}}>
            <img src="images/vepp-driver.png" alt="vepp-driver" />
            <div>
                <h1 style={{margin: 0}}>Earn while you drive.</h1>
                <p>Sign up as a driver and get access to rides on our platform</p>
            </div>
        </div>
        {pstate.view === 'F' && <div className="driver-registration-form" style={{backgroundColor: theme.surface1, padding: 16}}>
            <h2 style={{color: theme.onSurfaceVariant, marginTop: 0}}>Sign up</h2>
        <form>
            <div className='multi-input'>
            <div className="form-field full-width">
                <FormInput backgroundColor={theme.background} fullwidth label="First Name" highlight={theme.primary} name="registrationfirstname" onChange={valueChanged} required value={driver.registrationname} placeholder="Full name" type="text" />
            </div>
            <div className="form-field full-width">
                <FormInput backgroundColor={theme.background} fullwidth label="Last Name" highlight={theme.primary} name="registrationlastname" onChange={valueChanged} required value={driver.registrationname} placeholder="Full name" type="text" />
            </div>
            </div>
            <div className="form-field full-width">
                <FormInput backgroundColor={theme.background} label="Email" fullwidth highlight={theme.primary} name="registrationemail" onChange={valueChanged} required value={driver.registrationemail} type="email" placeholder="Email" />
            </div>
         
            <div className="form-field full-width">
                <FormInput highlight={theme.primary} label="Phone number" fullwidth backgroundColor={theme.background} name="registrationphone" onChange={valueChanged} required value={driver.registrationphone} placeholder="Phone number" type="tel" />
            </div>
            <div className="form-field full-width password">
                <FormInput highlight={theme.primary} fullwidth label="Password" backgroundColor={theme.background} name="registrationpassword" onChange={valueChanged} required value={driver.registrationpassword} placeholder="Password" type={!showpasword ? 'password' : 'text'} />
                <span onClick={toggleIcon} className="suffix-icon" style={{marginTop: 8, cursor: "pointer"}}>{ showpasword ? <VisibilityOffIcon fill={theme.onBackground} /> : <VisibilityIcon fill={theme.onBackground} />}</span>
            </div>

          
           
            <div className="tos">
            <p style={{color: theme.onSurfaceVariant}}>By signing up, i agree to vepp's terms and conditions. and i acknowledge that i have read the Privacy Policy</p>
           </div>
           <div className='app-buttons'>
               <button style={{backgroundColor: theme.primary, color: theme.onPrimary}} onClick={preSave} disabled={!DriverValidator(driver)}>Continue</button>
           </div>
        </form>
       
        </div>
       }
       {
        pstate.view === 'C' && <div style={{marginTop: 64}}> 
            <p style={{color: theme.onBackground}}>We sent a code to {driver.registrationphone} please enter code to continue</p>
            <div>
                <FormInput backgroundColor={theme.background} color={theme.onBackground} fullwidth label="code" name="code" onChange={valueChanged} />
                <div className='app-buttons'>
                    <button onClick={saveRegistration} style={{backgroundColor: theme.primary, color: theme.onPrimary}}>Continue</button>
                </div>
                <p style={{color: theme.primary, cursor: 'pointer'}} onClick={preSave}>Resend code</p>
            </div>
        </div>
       }
       {
        pstate.view === 'S' && <div style={{maxWidth: 640, marginTop: 64, color: theme.onBackground}}>
            <h1>Driver account created!</h1>
            <p>We will reach out to you abougt the next steps</p>
            <p>In the mean time log in and complete you profile</p>
        </div>
       }
    </div>
    </>)
}

export default RegisterDriver;
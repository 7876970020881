import { Map, View } from "ol";
import { boundingExtent } from "ol/extent";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, get, transformExtent } from "ol/proj";
import { XYZ } from "ol/source";
import { useEffect, useRef, useState } from "react";
import MapService from "../services/map-service";

const AppMap = ({clickHandler, gpx, markers=[]}) => {
    const [map, setMap] = useState(null);
    const routeLayerRef =  useRef(null);
    
    let mapMarkers = useRef([])

    useEffect(() => {
        AddMap();
    }, []);


    useEffect(() => {
        if(map && gpx) {
            
           const route = MapService.AddRoutePath(gpx);
           if(routeLayerRef.current) {
                map.removeLayer(routeLayerRef.current)
        }
      
        routeLayerRef.current =  route;
        map.addLayer(routeLayerRef.current);
       const res =  map.getView().getResolution();
          map.getView().fit(routeLayerRef.current.getSource().getExtent(), {
         size: map.getSize(),
         maxZoom: 16,
         duration: 500
       });
      

      // map.getView().setResolution(res);

       } else {
        if(routeLayerRef.current) {
            map.removeLayer(routeLayerRef.current);
            routeLayerRef.current = null;
        }

       }
    }, [gpx, map]);


    useEffect(() => {
      
        if(map && markers.length > 0) {
        for (let layer of mapMarkers.current) {
            
            map.removeLayer(layer);
        }
        mapMarkers.current = [];
        for (let marker of markers) {
            
            const mk = MapService.AddMarker(marker);
            
            mapMarkers.current.push(mk);
            map.addLayer(mk);
        }
        zoomToMarkers();
    } else {
        for( let mark of mapMarkers.current) {
            map.removeLayer(mark);
        
        }
        mapMarkers.current = [];
    }
    } , [markers]);


    const zoomToMarkers = () => {
        const mk = markers.map(m => [m.lon, m.lat]);
        console.log(mk);
        let mbound = boundingExtent(mk);
        mbound = transformExtent(mbound, get('EPSG:4326'), get('EPSG:3857'));
        map.getView().fit(mbound, {
            size: map.getSize(),
            maxZoom: 16,
            duration: 500
          });
        
        
    }

    

    
   
    const AddMap =() => { 
      
        const vmap =  new Map({
             target: 'map',
             layers: [
                 new TileLayer({
                     source: new XYZ({
         
                         //url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        url:'https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=50c413e6187a4308918d9c654d170fc5'
                     })
                 })
             ],
             view: new View({center: fromLonLat([-61.3794, 15.3092]), zoom: 16})
             
         });
         
         vmap.on('click', (e) => {
            if(clickHandler) {
            clickHandler(e)
            } else {
                return;
            }
            // const lonLat = map.getLonLatFromPixel(e.pixel);
         })
 
 
         
        
        setMap(vmap)
        }

  

    return (
        <div  className="app-map" id="map"></div>
    )
}

export default AppMap;
import { useEffect, useRef, useState } from "react";
import CameraIcon from "../icons/camera";
import EditIcon from "../icons/edit";
import '../styles/photo-select.css';
import { useTheme } from "../contexts/theme-context";
import ImageIcon from "../icons/image";

const PhotoSelect = ({ width = 192, height = 192, photoTaken, initialPhoto}) => {
    const [snap, setSnap] = useState(false);
    const [photobrowse, setPhotobrowse] = useState(false);
    const fileInputRef = useRef(null);
    const picRefCanvas = useRef(null);
    const picRefImg = useRef(null);
    const vidRef = useRef(null);
    const [streaming, setStreaming] = useState(false);
    const [dim, setDim] = useState({ width: 0, height: 0 });
    const [vidstream, setVidstream] = useState(null);
    const [data, setData] = useState(null);
    const [inputval, setInputval] = useState(null);
    const filepicref = useRef(null);
    const [picfile, setPicfile] = useState(false);
    const [done, setDone] = useState(false);
    const [theme] = useTheme();

    const browseFiie = () => {
      
        clearPhoto();
        stopStream();
        setStreaming(false);
        setSnap(false);
       
        fileInputRef.current.click();
    }

    useEffect(() => {
        if (vidRef.current)
            vidRef.current.addEventListener('canplay', (ev) => {
                const height = vidRef.current.clientHeight;
                const width = vidRef.current.clientWidth;
                console.log(width, height);
                setDim({ width, height });

            });
    });



    useEffect(() => {
        if (snap && streaming) {
            startCapture();
        }
    }, [snap, streaming])

    useEffect(() => {
        if(picfile) {
            setData(fileInputRef.current.files[0]);
            filepicref.current.src = URL.createObjectURL(fileInputRef.current.files[0]);
            setDone(true);
        }
    }, [picfile])

    const showPhotoSelector = () => {
        setPhotobrowse(true);
    }

    const startStream = (e) => {
        e.preventDefault();
        if(picRefCanvas.current) {
        picRefCanvas.current.width = 0;
        picRefCanvas.current.height = 0;
        }
        setSnap(true);
        setStreaming(true);
    }

    const startCapture = () => {
        clearPhoto();
        navigator.mediaDevices.getUserMedia({ video: {width: 720, height: 720}, audio: false }).then((stream) => {
            setVidstream(stream);
            vidRef.current.srcObject = stream;
            vidRef.current.play();

        }).catch(err => console.log('Oops something went wrong'));
       
    }

    const closeCapture = () => {
        if(snap) {
        clearPhoto();
        if(vidRef.current) {
        vidRef.current.srcObject = null;
        }
        }
        stopStream();
        setStreaming(false);
        setPhotobrowse(false);
        setPicfile(false);
        setData(null);
    }

    const capture = () => {
        picRefCanvas.current.width = dim.width;
        picRefCanvas.current.height = dim.height;
        
        const context = picRefCanvas.current.getContext('2d');
        context.drawImage(vidRef.current, 0, 0, dim.width, dim.height);
        picRefCanvas.current.toBlob((blob) => {
            setData(blob);
            const url =  URL.createObjectURL(blob);
            picRefImg.current.setAttribute('src', url);
            setDone(true);
            if(vidRef.current) {
            vidRef.current.srcObject = null;
            }
          
        })//toDataURL('image/png');
        stopStream();
        setStreaming(false);
        

        

    }

    const fileSelected = () => {
        setPicfile(true);
    }

    const stopStream = () => {
        if(vidstream) {
        vidstream.getTracks().forEach(function(track) {
            track.stop();
          });
        }
    }


    const clearPhoto = () => {
        setData(null);
        if(picRefCanvas.current)  {
            picRefCanvas.current.width = 0;
            picRefCanvas.current.height = 0;
        
            var context = picRefCanvas.current.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, dim.width, dim.height);

            var data = picRefCanvas.current.toDataURL('image/png');
            picRefImg.current.setAttribute('src', data);
        }
        fileInputRef.current.value = null;
        if(filepicref.current) {
            filepicref.current.src  = '';
            setPicfile(false);
        }
    }

    const doneWithPhoto = () => {
        photoTaken(data);
        setPhotobrowse(false);
        setPicfile(false);
        setStreaming(false);
        setDone(false);
    }

    return (
        <>
            <div style={{ width, height }} className="avatar">
                {initialPhoto ? <img src={initialPhoto} alt="user" width={width} height={height} /> : <CameraIcon width={width} height={width} />}
                <button onClick={showPhotoSelector} className="edit-btn"><ImageIcon fill={theme.primary} /></button>
                <input onChange={fileSelected} ref={fileInputRef} style={{ display: "none" }} type="file" />
            </div>
            {photobrowse ?
                <div className="source-select-container" style={{zIndex: 999}}>
                    <div className="source-select">
                        <button onClick={browseFiie} >Browse</button>
                        <button disabled={snap && streaming} onClick={startStream}>Camera</button>
                        <button onClick={closeCapture}>Cancel</button>
                        {picfile ? <img style={{marginTop: 16}} className="vid-snap" alt="profile pic" ref={filepicref} /> : null }
                        {snap ? <div style={{marginTop: 16}}> 
                            <div>
                                {streaming ? <video className="vid-snap" ref={vidRef}></video> : null}
                                <canvas height={0} className="vid-canvas" ref={picRefCanvas}>
                                    <div class="pic">
                                        <img width={dim.width} ref={picRefImg} alt="capture" />
                                    </div>
                                </canvas>
                               
                            </div>
                        </div> : null}
                        <div style={{display: "flex", justifyContent: "center", marginTop: 10}} >
                                    {streaming ? <button onClick={capture}><CameraIcon height={24} /></button>: null }
                                    {!streaming && data && done ? <button onClick={doneWithPhoto}>Done</button> : null}
                        </div>
                    </div>

                </div> : null}
        </>
    );
}

export default PhotoSelect;
import DriverApi from "./driver-api";
import PlaceApi from "./place-api";
import RideApi from "./ride-api";
import UserApi from "./user-api";
import PayApi from "./pay-api";
import VehicleApi from "./vehicle-api";
import ConfigApi from "./config-api";

const DriverService = DriverApi();
const UserService =  UserApi();
const RideService = RideApi();
const PlaceService = PlaceApi();
const VehicleService = VehicleApi();


export {
    UserService,
    DriverService,
    RideService,
    PlaceService,
    PayApi,
    VehicleService,
    ConfigApi
    
}
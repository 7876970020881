import { reset } from "ol/transform";
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import FormInput from "../components/form-input"
import { useTheme } from "../contexts/theme-context";
import VisibilityIcon from "../icons/visibility"
import VisibilityOffIcon from "../icons/visibility-off";
import { UserService } from "../services/rest";

const ResetPassword = () => {

    const [showpwd, setShowpwd] = useState(false);
    const [theme] = useTheme();
    const loc =  useLocation();
    const [valid, setValid] =  useState(false);
    const [show, setShow] = useState(false);
    const [password, setPassword] =  useState('');
    const [message, setMessage] = useState(null);
    const [usr, setUsr] =  useState(null);
    const checkResetCode = useCallback( async () => {
      //  e.preventDefault();
        try {
            const {uid, resetcode} = loc.state;
            
            const res = await UserService.CheckResetCode(uid, resetcode);
            setUsr({...res, uid, resetcode});
            if(!res.valid) {
                console.log('Hoo yah');
                setValid(false);
            }
            setValid(true);

        } catch (ex) {
            console.log(ex);

        } finally {
            setShow(true);
        }

    } ,[]);


    useEffect(() => {

        checkResetCode();

    }, [checkResetCode])

    const resetPassword = async (e) => {
        try {
            e.preventDefault();
            setMessage(null);
            const res =  await UserService.ResetPassword(loc.state.uid, {
                resetcode: loc.state.resetcode,
                password
            });
            if(res) {
            setShow(false);
            setMessage(<span>Your password was reset. <Link to="/login-select">Login</Link> to continue to use Vepp</span>)
            }


        } catch (ex) {
            setMessage(ex.message);
        }

    }

    

    return (
        <>
        {show ? <div className="forget-password">
            {valid ? <form>
                <p className="title" style={{color: theme.foreground}}>Reset your password</p>
                <div>
                    <FormInput value={password} onChange={(e) => setPassword(e.target.value)} highlight={theme.accent} color="#444" labelColor="#444" fullwidth label="Enter password" type= {showpwd ? "text" : "password"} suffix={!showpwd ? <VisibilityIcon onClick={() => setShowpwd(s => !s)} /> : <VisibilityOffIcon onClick={() => setShowpwd(s => !s)} />} />
                </div>
                <div className="app-buttons">
                    <button onClick={resetPassword} class="accent">Submit</button>
                </div>
            </form>
            :
            <div style={{color: theme.accent, textAlign: 'center', width: '100%'}}>
                <p>Invalid operation</p>
            </div>
          }
        </div>
        : null 
        }
       {message ? <div style={{color: theme.accent, textAlign: 'left', padding: 16}}>
           <p>{message}</p>
       </div>
       : null
       }
    </>
    )
}

export default ResetPassword;
import { useEffect } from "react";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { RideService, UserService } from "../services/rest";

const DriverRideList = ({rides, fetching}) => {
    const control = new AbortController();
    const signal =  control.signal;
    useEffect(() => {

        return () => {
            if(!signal.aborted) {
            control.abort();
        }
        }

    }, [])

    const [store, dispatch] = useStore();
    const getRide = async (id) => {
        try {
        fetching(true);
        const res = await RideService.GetRide({id, signal});
        dispatch({type:'update-ride', payload: res});
        fetching(false);
        } catch (err) {
            fetching(false);
        }
    }
    const [theme] =  useTheme();
    return <>
       <div className="ride-list" style={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
        <ul style={{backgroundColor: theme.surface, color: theme.onSurface}}>
            {rides.map(r => (
                <li onClick={() => getRide(r._id)} key={r._id} style={{backgroundColor: theme.surfaceVariant, color: theme.onSurfaceVariant}}>
                   <div style={{width: 48, height: 48, display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center" }} className="avatar">
                    {UserService.GetInitials(r.userDetails?.fullname)}
                   </div>
                    <div style={{display: "flex", flexDirection: "column", flex: '1 1 auto', marginLeft: 16}}>
                    <div style={{display: "flex", fontSize: '1.25rem'}}><span>{r?.locationDetails?.shortname}</span> <span style={{marginLeft: 32}}>{r.cost.toLocaleString('en-US', {style: "currency", currency: 'USD'})}</span></div>
                    <div style={{marginTop: 8}} className="ride-mets">
                       <span>{r?.stops?.length} {r?.stops?.length > 1 ? 'stops': 'stop'}</span>
                       <span>{(r.distance/1000).toFixed(2)} KM</span>
                        <span>{r.duration.toFixed(0)} Minutes</span>
                    </div>
                   </div>
                </li>
            ))}
        </ul>
       </div>
    </>
}

export default DriverRideList;
import { Strings } from "../settings"

const PlaceApi = ()=> {
    const SearchPlace = (needle) => {
        return fetch(`${Strings.api}/place/search?q=${needle}&limit=5`).then(res => {
            if(!res.ok) {
                throw new Error('Network error')
            }
            return res.json();
        });
    }


    const GetPlacesNear = (coords) => {
        return fetch(`${Strings.api}/place/near?lat=${coords.lat}&lng=${coords.lon}`).then(r => {
            if(!r.ok) {
                throw new Error('Network error');
            }
            return r.json();
        });
    }

    const ReverseGeocodePlace = async (lat, lon) => {
        try {
            const res = await fetch(`${Strings.api}/place/reversegeocode?lat=${lat}&lon=${lon}`);
            if(!res.ok) {
                throw new Error('Network error');
            }
    
            return await res.json();
    
        } catch (ex) {
            throw ex;
        }
    }
    

    return {
        SearchPlace,
        GetPlacesNear, 
        ReverseGeocodePlace
    }
}



export default PlaceApi;
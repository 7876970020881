import { useEffect, useState } from "react";
import { useTheme } from "../contexts/theme-context";
import BackArrowIcon from "../icons/back-arrow";
import ExpandLessIcon from "../icons/expand-less";
import ExpandMoreIcon from "../icons/expand-more";

const RideSumary = ({ride, title, backButton = false, backButtonClicked = () => {}, active=false}) => {
    const [expanded, setExpanded] = useState(false)
    const [theme] = useTheme();
    useEffect(() => {
        if(!active) {
            setExpanded(true);
        }

    }, [active])
   
    return  (
        ride ?
        <div style={{display: "flex", flexDirection: "column",  borderRadius: 16, color: theme.onSurfaceVariant}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
            {title ? <div className="title" style={{padding: '12px 16px'}}>{title}</div> : null}
            <span style={{flex: '1 1 auto'}}></span>
            {backButton ? !active ? <button style={{pointerEvents: 'auto', width: 48, height: 48, textAlign: 'center', border: 'none', outline: 'none', backgroundColor: 'transparent'}}  onClick={backButtonClicked}><BackArrowIcon /></button> :<button style={{backgroundColor: 'transparent'}} onClick={(e) => {e.preventDefault(); setExpanded(ex => !ex)}} className="icon-button"> {expanded ? <ExpandLessIcon width={48} height={48} fill={theme.onSurfaceVariant}  /> : < ExpandMoreIcon fill={theme.onSurfaceVariant} />}</button> : null}
            </div>
          
            {expanded ? 
              <div>
            <div style={{animation: 'slidedown-placessearch .2s'}}> 
               <ul className="ride-stops" style={{padding: '0 32px', listStyle: "none"}}>
                   <li>{ride.startdetails?.placename}</li>
                  {ride.stopdetails?.map((s,i) => (<li key={i}>
                      {s.placename} 
                  </li>))}
               </ul>
            </div>
            {
                ride?.package?.description ? 
                <div>
                    <p>Package Value: ${ride.package?.value}</p>
                </div>: null
            }
            </div> : null}
        </div>
   : null );
}

export default RideSumary;
import { Strings } from "../settings"

const RideApi = ()=> {
    const PostRide = (data)=> {
        return fetch(`${Strings.api}/ride`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if(!res.ok) {
                throw new Error('Network error');
            }
            return res.json();
        });
    }


    const GetCost = (rideid) => {
        return fetch(`${Strings.api}/ride/estimate/${rideid}`, {method: 'PATCH'}).then(r => {
            if(!r.ok) {
                throw new Error('Network error');
            }
            return r.json();
        });
    }

    const GetRideDetails = (data) => {
        console.log(data);
        return fetch(`${Strings.api}/ride/details?start=${data.origin}&stops=${data.stops}`).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json()
        });
    }


    const ListRidesInRadius = ({signal, radius=5, center} = {}) => {
        return fetch(`${Strings.api}/ride/radius?radius=${radius}&center=${center}`, {signal}).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        });
    }


    const GetRide = ({id, signal}) => {
        return fetch(`${Strings.api}/ride/${id}`).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong')
            }
            return r.json();
        });
    }

    const SelectRIde = ({id, signal, data}) => {
        
        return fetch(`${Strings.api}/ride/assigndriver/${id}`, {
            signal, 
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": 'application/json'
            }
        }).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        });

    }


    const GetPendingRideDriver  = (driverid, signal) => {
        return fetch(`${Strings.api}/ride/driverpending/${driverid}`, {
            signal,
            headers: {
               "Content-Type": 'application/json'
            }
        }).then(r => {
            if(!r.ok) {
                throw new Error('Oops somethisn went wrong');
            }
            return r.json();
        });
    }


    const CancelPendingRide = (rideid, driverid, signal) => {
        return fetch(`${Strings.api}/ride/drivercancel/${rideid}/${driverid}`, {
            method: 'PATCH',
            signal,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        })
    }


    const StartRide = (rideid, driverid) => {
        return fetch(`${Strings.api}/ride/pickup/${rideid}/${driverid}`, {
            method: 'PATCH',
            headers: {
                "Content-Type":"application/json"
            }
        }).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        });
    };

    const CompleteRide = (rideid, data) => {
        console.log(rideid);
        return fetch(`${Strings.api}/ride/complete/${rideid}`, {
            method: 'PATCH',
            body:  JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            if(!r.ok) {
                throw new Error('Oops somethjing went wrong');
            }
            return r.json();
        });
    }


    const GetRunningCost = (id, coords) => {
        return fetch(`${Strings.api}/ride/runningcost/${id}?stops=${coords}`).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        })

    }

    const GetRideHistory = (driver, status, signal) => {
        return fetch(`${Strings.api}/ride/driverhistory/${driver}/${status}`, {signal}).then(r => {
            if(!r.ok) {
                throw new Error('Oops something went wrong');
            }
            return r.json();
        });

    }

    const GetActiveRideRider = async (riderid) => {
        try {
            const res =  await fetch(`${Strings.api}/ride/activerider/${riderid}`, {
                headers: {
                     Authorization: `Bearer ${window.localStorage.getItem('vep-token')}`,
                     'Content-Type': 'application/json',
                     'api': 'NO_KEY'
                }
            });
            if(!res.ok) {
                const error =  await res.json();
                
                throw new Error(error);
            }
            const data = await res.json();
            return data;

        } catch (ex) {
            throw ex;
        }

    }

    const GetTrips = async (riderid, status) => {
        try {
            const res =  await fetch(`${Strings.api}/ride/clientride/${riderid}?status=${status}`);
            if(!res.ok) {
                const err = await res.json();
                throw new Error(err);
            }
            const data =  await res.json();
            return data;

        } catch (ex) {
            throw ex;
        }
    }


    const RiderCancelRIde = async(rideid) => {
        try {
            const res = await fetch(`${Strings.api}/ride/ridercancel/${rideid}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if(!res.ok) {
                const err = await res.json();
                throw new Error(err);
            }
            const data = await res.json();
            return data;

        } catch (ex) {
            throw ex;
        }

    }

    return Object.freeze({
        PostRide,
        GetCost,
        GetRideDetails,
        ListRidesInRadius,
        GetRide,
        SelectRIde,
        GetPendingRideDriver,
        CancelPendingRide,
        StartRide,
        CompleteRide,
        GetRunningCost,
        GetRideHistory,
        GetActiveRideRider,
        GetTrips,
        RiderCancelRIde
    });

}

export default RideApi
import { useRef, useState } from "react";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { RideService } from "../services/rest";
import { KwButton } from "./buttons";
import Snack from "./snack";
import Spinner from "./spinner";
const CashPaymentInfo = ({actionCallback = (a) => {}}) => {
    const [theme] = useTheme();
    const [store,dispatch] = useStore();
    const [spin, setSpin] = useState(false);
    const snack = useRef(null);

    const confirmRide = async () => {
        try {
            setSpin(true);
            const currpayment = {status: 'pending', method: 'cash', total: store.ride.cost};
            
            dispatch({type: 'update-ride', payload: {payment: currpayment}});
            const ridedata = {...store.ride, payment: currpayment, requestedby: store.user.uid};
            delete ridedata.gpx;
            delete ridedata.startdetails;
            delete ridedata.stopdetails;
            const r = await  RideService.PostRide(ridedata);
            setSpin(false);
            dispatch({type: 'update-ride', payload: r});
             actionCallback('CONFIRM');
        } catch (ex) {
            snack.current.show('Oops something went wrong', "error")
            setSpin(false);

        }
    }
    return (
    <>
    <div className="cash-pay" style={{background: theme.secondaryContainer, color: theme.onSecondaryContainer, padding: 16, boxSizing:"border-box", borderRadius: 16, overflow: 'hidden', marginTop: 132   }}  >
    <h1>You have selected to pay by cash!</h1>
    <h1>Cost: {store.ride?.cost.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</h1>
    <p style={{textAlign: 'justify'}}>This means that you have agreed to pay at the end of this trip. Failure to do so will result in your acount being suspended and you will be banned from futher using our services</p>
    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 16}}>
        <KwButton background={theme.primary} hoverColor={theme.primaryContainer} border={theme.outline} highlight={theme.primary} fullwidth color={theme.onPrimary} onClick={() => confirmRide()} label="Confirm" />
        <KwButton  onClick={() => actionCallback('CANCEL')} background={theme.primary} hoverColor={theme.primaryContainer} border={theme.outline} highlight={theme.primary} fullwidth color={theme.onPrimary} label="Cancel" />
               
            </div>
    {spin ? <Spinner size={80} color={theme.primary} /> : null}
    </div>
    <Snack show={snack} />
    </>
    );
}

export default CashPaymentInfo
import { ValidateEmail, ValidatePhone } from "./app-validators";

const DriverValidator = (driver) => {
    if(!ValidateEmail(driver.registrationemail) || !ValidatePhone(driver.registrationphone)) {
        
        return false;
    }
    
    return driver.registrationfirstname && driver.registrationlastname && driver.registrationemail && driver.registrationphone && driver.registrationpassword;
    
}
export default DriverValidator;
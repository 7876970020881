import { createContext, useContext, useEffect, useReducer } from "react";
import AuthService from "../services/auth-service";
import { UserService } from "../services/rest";
import {appReducer, initialstate} from '../services/app-reducer';

export const AppContext = createContext({
    state: initialstate,
    dispatch: () => null
});

export const AppProvider = ({children}) =>{
    const [state, dispatch] =  useReducer(appReducer, initialstate);
    useEffect(() => {
    const isloggedin =  AuthService.UserLoggedIn();
    if(isloggedin) {
      
        UserService.GetUser(AuthService.AuthDetails().uid).then(a => {
           
            dispatch({type: 'initiale-data', payload: {driverid: a.driverid ? a.driverid : null, user: {uid: a._id, firstname: a.firstname, lastname: a.lastname, email: a.email, telephone: a.telephone}}});
           
        }).catch(err => {
            console.log(err);
        });

    }
}, []);
    return (<AppContext.Provider value={[state, dispatch]}>
        {children}
    </AppContext.Provider>)

}

export const useStore = () => {
    return useContext(AppContext);
}
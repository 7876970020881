import { useState } from "react";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context"

const ConfirmComplete = ({actionCalback}) => {
   
    const [store, dispatch] = useStore();
    console.log(store.ride);
    const [theme] = useTheme();
    return (<div className="ride-info-driver" style={{backgroundColor: theme.background, color: theme.foreground, padding: 16}}>
        <div>
            <h2>{store.ride?.runningcost?.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</h2>
        </div>
        <div className="app-buttons" style={{marginTop: 16}}>
            <button onClick={() => actionCalback('CONFIRMDONE')}  className="full-width">Confirm complete</button>
            <button onClick={() => actionCalback('CACNCELDONE')} className="full-width">Cancel</button>
        </div>
    </div>)
}

export default ConfirmComplete
import { useState } from "react";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import Spinner from "./spinner";

const UserCancel = ({action=(a) => {}, spinner=false, message}) => {
    const [agree, setAgree] =  useState(false);
    const [store, dispatch] =  useStore();
    const [theme] =  useTheme();
    return (
        <div className="modal-positional" style={{display: 'flex' , justifyContent: 'center', flexDirection: "column", bottom: 0, padding: 16, boxSizing: "border-box"}}>
            <div>
                {spinner ? <div style={{paddingTop:16, textAlign: 'center'}}><Spinner color={theme.primary} size={48} /></div> : null}
                {message ? <p style={{textAlign: 'center', color: theme.foreground}}>{message}</p>: null}
                <p style={{textAlign: "center", color: theme.foreground}}>Are you sure you want to cancel this trip ?</p>
                
                {store.ride.status === 1 ? <p>This ride has already been selected by a driver. A cancelation fee  of $5.00 will be charged as per our terms and conditions</p> : null }
                <div className="app-buttons" style={{width: '100%'}}>
                    <button onClick={() => {action(true)}}>Yes</button>
                    <button onClick={() => action(false)}>No</button>
                </div>
            </div>
           

        </div>
    );
}

export default UserCancel;
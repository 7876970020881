import { useEffect, useState } from "react"
import { useTheme } from "../contexts/theme-context"
import RideSelect from "../pages/ride-select"
import Avatar from "./avatar"
import { KwButton } from "./buttons"
import RideSumary from "./ride-sumary"

const DriverRideInfo = ({ride, actionCallback}) => {
    const [theme] = useTheme();
    const [btns, setBtns] =  useState();
   


    const backToList = () => {
        actionCallback('LIST');
    }

    const SelectRide = () => {
        actionCallback('SELECT', {ride: ride._id})
    }
    let btns_disp = null;

    switch (ride?.status) {
        case 0:
            btns_disp = <>
            <KwButton 
                onClick={SelectRide} 
                label="Select ride" 
                fullwidth
                background = {theme.primary}
                border = {theme.outline}
                color = {theme.onPrimary}
                highlight = {theme.primary}
                hoverColor = {theme.primaryContainer}
                hoverTextColor = {theme.onPrimaryContainer}
                />
            <KwButton
                onClick={backToList}
                label="Go back" 
                fullwidth
                background = {theme.primary}
                border = {theme.outline}
                color = {theme.onPrimary}
                highlight = {theme.primary}
                hoverColor = {theme.primaryContainer}
                hoverTextColor = {theme.onPrimaryContainer}
                /> 
            </>
            break;
        case 1:
            btns_disp = <>
               <button onClick={() => actionCallback('START')}>Start</button>
               <button onClick={() => actionCallback('CANCEL')}>Cancel</button>
            </>
            break;
        case 2:
            btns_disp = <div style={{padding: '0 16px', width: "100%", boxSizing: "border-box"}}>
               <button onClick={() => actionCallback('DONE')} className="full-width" style={{marginRight: 0}}>Done</button>
            </div>
            break;
        default:
            btns_disp = null;
        
    }

    return (

       ride?._id ? <>
        <div className="ride-info-driver" style={{backgroundColor: theme.background, color: theme.primary, borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
            <div style={{backgroundColor: theme.surface, color: theme.onSurface, borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
               <div className="user-info"> <div className="avatar" style={{width: 48, height: 48, marginRight: 16, borderColor: theme.primary, color: theme.onSurface}}></div> <span style={{flex: "1 1 auto", fontSize:"1.5rem"}}>{ride?.userdetails?.fullname}</span>
               <span style={{fontSize: "1.5rem"}}>{ride?.cost?.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</span></div>
               <div style={{display: "flex", paddingLeft: 84}}>
                <span>{ride?.stops?.length} {ride?.stops?.length >1? 'srops': 'stop'} </span>
                <span style={{margin: "0 16px"}}>{(ride.distance /1000).toFixed(2)} KM</span>
                <span>Aprox. {ride.duration.toFixed(0)} Minutes</span>
               </div>
           </div>
           <div style={{padding: 16}}>
            <RideSumary ride={ride} />
           </div>
           <div style={{marginBottom: 16, padding: '0 16px'}} className="app-buttons">
            {btns_disp}
           </div>
        </div>
        </> : null
    )
}

export default DriverRideInfo;
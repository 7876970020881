
import Vector from 'ol/layer/Vector';

import Point from 'ol/geom/Point';
import { fromLonLat,transform } from 'ol/proj';
import  Feature  from 'ol/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import *  as Source from 'ol/source';
import { toSize } from 'ol/size';
import GPX from 'ol/format/GPX';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
 
import {Circle as CircleStyle, Fill, Stroke, Text} from 'ol/style';

const MapServiceFactory = () => {

    const style = {
        'Point': new Style({
          image: new CircleStyle({
            fill: new Fill({
              color: 'rgba(255,255,0,0.4)',
            }),
            radius: 5,
            stroke: new Stroke({
              color: '#37c397',
              width: 1,
            }),
          }),
        }),
        'LineString': new Style({
          stroke: new Stroke({
            color: '#f00',
            width: 3,
          }),
        }),
        'MultiLineString': new Style({
          stroke: new Stroke({
            color: '#37c397',
            width: 3,
          }),
        }),
      };
    
    const AddMarker = (data) => {
        const markerGeo = new Point(fromLonLat([data.lon, data.lat]));
        
        const markerFrature =  new Feature({
            geometry: markerGeo
        });
        const size = toSize(48);
       // const offset =  Pixel(-(size.w / 2), -size.h);
        const markerStyle = new Icon({
            src: data.icon ? data.icon : '/images/marker.png',
            
        });
       markerFrature.setStyle(new Style({
            image: markerStyle,
            text: new Text({
              text: data.title,
              scale: 1.2,
              fill: new Fill({
                color: "#fff"
              }),
              stroke: new Stroke({
                color: "0",
                width: 3
              })
            })
         
        }))
        const vectorSource = new Source.Vector({features: [markerFrature]});

        const markerLayer = new Vector({
            source: vectorSource
        });
      

        return markerLayer;

    }

    const AddRoutePath = (gpxdata) => {
        const vector = new VectorLayer({
            source: new VectorSource({
             // url: gpxdata,
              //format: new GPX().readFeature(gpxdata, {featureProjection: 'EPSG:3857'}),
            }),
            style: function (feature) {
              return style[feature.getGeometry().getType()];
            },
          });
          let GPXfeatures = (new GPX()).readFeatures(gpxdata, {featureProjection: 'EPSG:3857'})
          vector.getSource().addFeatures(GPXfeatures);
     
          return vector;

        
    }


    const TransformCoordinates = (coords) => {
        const trns = transform(coords, 'EPSG:3857', 'EPSG:4326');
        return trns;
    }

    return Object.freeze({
        AddMarker,
        AddRoutePath,
        TransformCoordinates
    })
}
const MapService = MapServiceFactory()
export default MapService;
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "../contexts/app-context";
import { RideService } from "../services/rest";
import '../styles/trip-list.css';
import { useTheme } from '../contexts/theme-context';
import Spinner from "../components/spinner";
import UserCancel from "../components/user-cancel";


const Trips = () => {
    const [rides, setRides] = useState([]);
    const [store] = useStore();
    const [theme] = useTheme();
    const [active, setActive] =  useState(true);
    const [spin, setSpinn] = useState(false);


    const getUpcomingRides = useCallback(async () => {
        try {
            if(active) {
            setSpinn(true);
            const res  = await RideService.GetTrips(store.user.uid, 0);
            setRides(res);
            }

        } catch (ex) {
            console.log(ex);
            // show error here.
        } finally {
            setSpinn(false);
        }
    }, [store.user, active]);

    const getRideHistory = useCallback(async () => {
        try {
        if(!active) {
            setSpinn(true);
        const  res =  await RideService.GetTrips(store.user.uid, -100);
        setRides(res);
        }
        } catch (ex) {
            console.log(ex);
        } finally {
            setSpinn(false);
        }

    }, [store.user, active])

    const typeSwitch = async (type) => {
       if(type === 0) {
        setActive(true);
       } else {
       setActive(false);
       }
    }


    useEffect(() => {
        if(store.user.uid && active) {
          getUpcomingRides();
        } else {
            getRideHistory();
        }
    }, [getUpcomingRides, store.user, active, getRideHistory]);


    


  
  
    return (<div>
        <h1 style={{textAlign: "left", padding: '0 16px', color: theme.foreground}}>Trips</h1>
        <div style={{display: "flex", alignItems: 'center' , padding: '0 16px'}}>
            <button style={{border: `2px solid ${theme.accent}`, borderRadius: 25 , marginRight: 16, backgroundColor: !active ? theme.background: theme.accent, pointerEvents: 'auto'}} onClick = {() => typeSwitch(0)}>Upcomming</button>
            <button style={{border: `2px solid ${theme.accent}`, borderRadius: 25 , marginRight: 16, backgroundColor: active ? theme.background: theme.accent, pointerEvents: 'auto'}} onClick = {() => typeSwitch(-100)}>History</button>
        </div>
        <ul className="kw-list" style={{paddingTop: 16}}>
            {rides.map((r) => (
                <li key={r._id} style={{color: theme.foreground}} class="kw-2col">
                    <div>
                    <div style={{padding: '4px 0', fontWeight: 500}}>
                       <span>Requested at </span>
                       <span>{moment(r.createddate).format('DD-MM-YYYY HH:mm')}</span>
                    </div>
                    <div style={{display: "flex", fontSize: '.75rem'}}>
                    <div style={{padding: '0 8px 0 0'}}>
                        <span>Start: </span>
                        <span>{r.origindetails.shortname}</span>
                    </div>
                    <div style={{padding: '0 8px'}}>
                        <span>End: </span>
                        <span>{r.laststopdetauls.shortname}</span>
                    </div>
                    <div style={{padding: '0 8px'}}>
                      
                      <span>{r.stops.length} {r.length >1 ? 'stops' : 'stop'}</span>
                    </div>
                    </div>
                    </div>
                    <div className="space"></div>
                    <div>
                        <button style={{borderRadius: 8}}>Cancel</button>
                    </div>
                </li>
            )
            )}
        </ul>
       {spin ?<div style={{position: 'absolute', height: 80, width: 80, left: '50%', top: '50%', marginLeft: -40, marginTop: -40}}> <Spinner /> </div>: null}
       <UserCancel />
    </div>)
}

export default Trips
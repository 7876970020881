import { useReducer, useState } from 'react';
import UserReducer from '../services/user-reducer';
import {UserService} from '../services/rest';
import '../styles/register-rider.css';
import FormInput from '../components/form-input';
import { useTheme } from '../contexts/theme-context';
import { FormatPhoneNumber } from '../services/formaters';
import AuthService from '../services/auth-service';
import { useNavigate } from 'react-router';
import { useStore } from '../contexts/app-context';
const RegisterRider = () => {
    const inituser = {fullname: '', email: '', telephone: '12684640430', password: '', code: ''};
    const [user, dispatchuser] =  useReducer(UserReducer, inituser);
    const [view, setView] =  useState('A');
    const [message, setMessage] =  useState('');
    const [theme] =  useTheme();
    const [store, dispatch] =  useStore();
    const nav =  useNavigate();

    const SaveUser = (e) => {
        e.preventDefault();
       UserService.RegisterUser(user).then(res => {
           if(res && res._id) {
               dispatchuser({type: 'update', payload: res});
               setView('B')
           }
           
       }).catch(ex => {
           setMessage('Oops something went wrong');
       });
    }

    const ValueChange = (e) => {
        
        const {value, name} =  e.target;
        dispatchuser({type: 'update', payload: {[name]: value}});
    } 

    const SubmitCode = (e) => {
        e.preventDefault();
        setMessage('');
        console.log(user);
        UserService.ProcessCode(user._id, user.code).then(r => {
            AuthService.SetAuthDetails({uid: user._id, token: r.token});
            //dispatch to update user in app data
            dispatch({type: 'update-user', payload: r});
            nav("/");

            
        }).catch(er => {
           setMessage(`That did not work. Please enter the code sent to ${FormatPhoneNumber(user.telephone)}`);
        })

    }

    const ResendCode = (e) => {
        e.preventDefault();
        setMessage('');
        UserService.ResendCode(user._id).then(r => {
            console.log(r);
            setMessage(`Please enter the code sent to ${FormatPhoneNumber(user.telephone)} `)
        }).catch(ex => {
            console.log(ex);
            setMessage('Oops something went wrong');
        })

    }
    
    return (<div className="rider-reg-page">
    <div className='side-show' style={{color: theme.onBackground}}>
        <img src='images/taxi-night.png' alt="taxi" />
        <div style ={{fontSize: '1.25rem'}}>
        <p>Get access to our platform by signing up.</p>
        <p>Move around on your own time without long waits</p>
        </div>
    </div>
    <div className="register-rider" style={{backgroundColor: theme.surface1}}>
    { view === 'A' ? <> 
        <h1 style={{color: theme.onBackground, marginTop: 0, textAlign: 'left'}}>Sign up</h1>
        <form>
            <div className="form-field full-width">
                <FormInput highlight={theme.accent} color={theme.onBackground} backgroundColor={theme.background} value={user.fullname} fullwidth onChange={ValueChange} required id="fullname" name="fullname"  label="Your name(required)" />
            </div>
            <div className="form-field full-width">
                <FormInput color={theme.onBackground} backgroundColor={theme.background} fullwidth value={user.telephone} onChange={ValueChange} required id="telephone" name="telephone" type="tel" highlight={theme.accent} label="Telephone(required)" />
            </div>
            <div className="form-field full-width">
            
                <FormInput color={theme.onBackground} backgroundColor={theme.background} fullwidth value={user.email} onChange={ValueChange} required id="email" name="email" type="email" highlight={theme.accent} label="Email(required)" />
            </div>
            <div className="form-field full-width">
                <FormInput color={theme.onBackground} backgroundColor={theme.background} fullwidth value={user.password} onChange={ValueChange} required id="password" name="password" type="password" highlight={theme.accent} label="Password(required)" />
            </div>
            <div className="app-buttons">
                <button style={{backgroundColor: theme.accent}} onClick={SaveUser}>Sign up</button>
            </div>
        </form>
        </> :
        <div className="code-enter">
            <form>
            <label style={{color: theme.foreground}}>Pleas enter code sent to {FormatPhoneNumber(user.telephone)}</label>
            <div className="form-field"><FormInput fullwidth color={theme.onBackground} backgroundColor={theme.background} label="code" highlight={theme.accent} value={user.code} onChange={ValueChange} name="code" max={6} /></div>
            <div className='app-buttons'>
                <button style={{backgroundColor: theme.accent}} onClick={SubmitCode}>Submit</button>
            </div>
            </form>
            <p className="resend-code"><button style={{color: theme.accent}} onClick={ResendCode} >Resend code</button></p>
            <p style={{color: theme.accent}}>{message}</p>
        </div> }
    </div>
    </div>)
}

export default RegisterRider
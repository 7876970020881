import { accent, primaryText } from '../colors';
import '../styles/rider-login.css';
import FormInput from '../components/form-input';
import { useEffect, useState } from 'react';
import { ConfigApi, UserService } from '../services/rest';
import AuthService from '../services/auth-service';
import {Link, useNavigate} from 'react-router-dom';
import { useStore } from '../contexts/app-context';
import { useWsock } from '../contexts/Wsock';
import { useTheme } from '../contexts/theme-context';
const RiderLogin = () => {
    const [store, dispatch] =  useStore();
    const [login, setLogin] = useState({email: '', password: ''});
    const [message, setMessage] = useState(null);
    const [theme, dispatchTheme] =  useTheme();
    const wsock = useWsock();
    const nav = useNavigate();
    const SubmitLogin = (e) => {
        e.preventDefault();
       
        LoginUSer();

    }

    useEffect(() => {
        dispatchTheme({type: 'toolbar-width', value: '100%'})
    }, [])

    const LoginUSer = async () => {
        try {
        setMessage(null);
        const res =  await  UserService.LoginUser(login);
          
            AuthService.SetAuthDetails(res);
            const conf =  await ConfigApi.GetConfig();
            console.log(conf);
          
            dispatch({type: 'set-config', payload: conf});

            wsock.emit('auth-message', {userid: res.uid});
            dispatch({type: 'update-user', payload: res});
            if(res.driverid) {
                dispatch({type: 'update-driverid', payload: res.driverid});
            }
           
            if(res.driverid) {
                if(res.driver_details?.registrationstatus === 0 ) {
                    nav('/driver-profile', {replace: true})
                } else {
                    nav('/driver', {replace: true});
                }
            } else {
                nav('/', {replace: true});
            }
        }
        catch(ex) {
            console.log(ex);
            setMessage('Could not log you in. Please try again')
        } 

    }

    return (
        <div className="rider-login">
            <div className='side-show'>
             <img src='images/taxi-night.png' alt='vepp app' />
            </div>
            <div className="login-form"  >
               
                <form  style={{backgroundColor: theme.surface1}}>
                <h1 style={{marginTop: 0, color: theme.onBackground}} className="title-medium">Sign in</h1>
                <div style={{textAlign: 'center'}}>
                    <FormInput backgroundColor={theme.background}  onChange={(e) => {
                        setLogin(l => ({...l, email: e.target.value}))
                    }} value={login.username} textAlign="center" label="Email or telephone" highlight={accent} color={theme.onBackground} labelColor={theme.onBackground} fullwidth />
                </div>
                <div>
                    <FormInput onChange={(e) => {
                        setLogin(l => ({...l, password: e.target.value}))
                    }} 
                    textAlign = "center"
                    value={login.password} type="password" backgroundColor={theme.background} label="Password" labelColor={theme.onBackground} highlight={accent} color={theme.onBackground } fullwidth />
                </div>
                <div  className="app-buttons">
                    <button style={{width: '100%', margin: 0}} onClick={SubmitLogin} className="accent">Login</button>
                </div>
                {message ? <p style={{fontSize: '.8rem', color: theme.error}}>{message}</p> : null}
                <div style={{marginTop: 32}}>
                <p style={{textAlign: 'left', fontSize: '.9rem', color: theme.onBackground}}>Dont have an account? <Link to="/register-rider"><span style={{color: accent}}>Sign up</span></Link></p>
                <p style={{color:accent, textAlign: "left", fontSize: '.9rem'}}><Link to="/forget-password">Forgot your password?</Link></p>
                </div>
                </form>
            </div>

        </div>
    )
}

export default RiderLogin
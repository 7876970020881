import { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import FormInput from "../components/form-input"
import { useTheme } from "../contexts/theme-context";
import VisibilityIcon from "../icons/visibility";
import { UserService } from "../services/rest";

const ForgotPassword = () => {
    const navigate= useNavigate();
    const [message, setMessage] = useState(null);
    const [validemail, setValidemail] =  useState(false);
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);
    const [usr, setUsr] = useState(null);
    const [theme] =  useTheme();
    const codebtn =  useRef(null);
  


    const validateUserEmail = async (e) => {
        try {
            e.preventDefault();
            const res =  await UserService.ForgotPassword(email);
            console.log(res);
            setUsr(res);
            setStep(2);
           

        } catch (ex) {
            console.log(ex);
        }

    }

    const checkCode = async (e) => {
        try {
            e.preventDefault();

            //call genetate reset link from here. on backend call process code
            console.log(typeof code);
            const res =  await UserService.GenerateResetLink(usr._id, {code: parseInt(code)});
            console.log(res);
            if(res._id) {
                navigate("/reset-password", {state: {resetcode: res.resetcode, uid: res._id}});
            }

        } catch (ex) {
            console.log(ex);
        }
    }

    const checkKeyUp = (e) => {
        if(e.Key ==='enter' || e.keyCode === 13) {
            codebtn.current.click();
        }
    }
    
    const emailValueChanged = (e) => {
        setEmail(e.target.value);
    }

    const CodeValueChanged = (e) => {
        console.log(e.target.type);
        
        setCode(e.target.value);
    }

    let page;
    switch(step) {
        case 2:
            page= <div className="forget-password">
               
                <div style={{width: '100%'}}>
                <p className="title" style={{color: theme.foreground}}>Code verification</p>
                    <div>
                    <FormInput type={"number"} onKeyUp={checkKeyUp} value={code} onChange={CodeValueChanged} labelColor="#444" highlight={theme.accent} color="#444" fullwidth label={`Please enter the code sent to  ${usr.telephone}`} />
                    </div>
                    <div className="app-buttons">
                        <button ref={codebtn} onClick={checkCode} className="accent">Submit</button>
                    </div>
                </div>
            </div>
            break;
        default:  
        page = <div className="forget-password">
        <form>
            <p className="title" style={{color: theme.foreground}}>User verification</p>
            <div>
                <FormInput onChange={emailValueChanged}  labelColor="#444"  highlight={theme.accent} color="#444" fullwidth type="email" required label="Please enter your email adress*" />
            </div>
            <div className="app-buttons">
                <button onClick={validateUserEmail} className="accent">Submit</button>
            </div>
        </form>
       </div>
    
    }


    return (
    <>
       {page}
   
    </>
    )
}

export default ForgotPassword;
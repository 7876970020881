import { Strings } from "../settings";

const VehicleApi = () => {
    const headers = {
        "Content-Type":'application/json',
        "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`
    }
    const ListVehiclesForDriver = async (driver, signal) => {
        try {
            const res =  await fetch (`${Strings.api}/vehicle/driver/${driver}`, {
                signal,
                headers
            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');

            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }


    const AddVehicle = async (data, signal) => {
        try {
            const res =  await fetch(`${Strings.api}/vehicle`, {
                method: 'POST',
                headers,
                signal,
                body: JSON.stringify(data)
            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        } 
    }


    const UpdateVehicle = async (id, data, signal) => {
        try {
            const res =  await fetch(`${Strings.api}/vehicle/${id}`, {
                method: 'PATCH',
                headers,
                signal,
                body: JSON.stringify(data)
            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        } 
    }


return Object.freeze({
    ListVehiclesForDriver,
    AddVehicle,
    UpdateVehicle

})
}

export default VehicleApi
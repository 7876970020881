export const initialstate = {ride: {payment: {}}, user: {}, driverid: null};


export const appReducer = (state, action) => {
    switch (action.type) {
        case 'update-ride':
           
            return {...state, ride: {...state.ride, ...action.payload}};
        case 'get-ride':
            return {...state.ride}
        case 'reset-ride':
            return {...state, ride: {}}
        case 'update-user':
            return {...state, user: {...state.user, ...action.payload}}
        case 'update-driverid':
            return {...state, driverid: action.payload}
        case 'initiale-data':
            return {...state, driverid: action.payload.driverid, user: {...action.payload.user}}
        case 'set-config':
            return{...state, config: action.payload}
        case 'reset':
            state = initialstate
            return state;
        default: 
            throw new Error('No action');
    }
}
import { useCallback, useEffect, useReducer, useState } from "react";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { DriverService } from "../services/rest";
import FormInput from "./form-input";
import DriverReducer from "../services/driver-reducer";
import Spinner from "./spinner";
import Alert from "./alert";

const DriverDetails = ({setStaus = s => null}) => {
    const [theme] = useTheme()
    const [driver, dispatch] =  useReducer(DriverReducer, {});
    const [store] = useStore();
    const [pstate, setPstate] = useState({spin: false, alert:{visible: false, text: '', sev: ''}});
    console.log(store.user);
    
    const getDriver = useCallback(async (contr) => {
        try {
            console.log('GETTHIS');
            const res =  await DriverService.GetDriver(store.driverid, contr.signal);
            setStaus(res.registrationstatus);
            dispatch({type: 'update', payload: res});

        } catch (ex) {
            console.log(ex);
            setPstate(p => ({...p, alert: {visible: true, text: 'Could load data. please try again', sev: 'error'}}));
        } finally {
            setPstate(p => ({...p, spin:false}));
        }
    }, [store.driverid]);

    useEffect(() => {
     
        const contr =  new AbortController();
        if(store.driverid) {
         getDriver(contr);
        }
       
        return () => {
            if(contr) {
            contr.abort();
            }
        }

    }, [getDriver, store.driverid])

    const updateDriver = async (e) => {
        e.preventDefault();
       
        try {
          
            setPstate( p => ({...p, spin: true}));
           
            const data = {alias: driver.alias, license: driver.license};
            await DriverService.UpdateDriver(store.driverid, data);
            setPstate(p => ({...p, alert: {visible: true, text: 'Your details were updated', sev: 'info'}}));
        }catch (ex) {
            console.log(ex);
            setPstate(p => ({...p, alert: {visible: true, text: ex.message, sev: 'error'}}));
        } finally {
            setPstate(p => ({...p, spin: false}))
        }
       
    }
    const valueChange = (e) => {
        const {value, name} =  e.target;
        console.log(name, value);
        dispatch({type: 'update', payload: {[name]: value}});
    }
    return ( 
        <div>
             <h3>Driver details</h3>
             {pstate.alert.visible && <div style={{marginBottom: 16}}> <Alert text={pstate.alert.text}  severity={pstate.alert.sev}/></div>}
            <form>
                
                <div className="form-fiels">
                    <FormInput name="alias" value={driver.alias} fullwidth label="Alias" backgroundColor={theme.background} onChange={valueChange} />
                </div>
                <div className="form-fields">
                    <FormInput name="license" value={driver.license} backgroundColor={theme.background} fullwidth label="License Number" onChange={valueChange} />
                </div>
                <div className="app-buttons">
                    <button onClick={updateDriver} style={{backgroundColor: theme.primary, color: theme.onPrimary, display: "flex", alignItems: "center", justifyContent: "center"}} >Save  {pstate.spin && <div style={{width: 36, height: 36, marginLeft: 8}}><Spinner size={24} /></div>}</button>
                </div>
            </form>
           
           
        </div>
    )
}
export default DriverDetails;
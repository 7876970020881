import { createContext, useContext } from "react";
let gSock;
class Wsock {
    #wsock;
    #eventemitters;
    #options
    constructor(wsurl, options) {
        this.#eventemitters =  new Map();
        this.#wsock =  new WebSocket(wsurl);
        this.#options =  options;
        this.#createListeners();
        
    }

    #createListeners() {
        this.#wsock.onopen =ev => {
            console.log('Socket open');
            this.#handleOpen();
        };

        this.#wsock.onmessage = ev => {
            this.#handleMessage(ev);
        };
        this.wsock.onclose =  () => {
            this.#handleClose()
        }
    }

    #handleMessage(m) {
        const data =  JSON.parse(m.data);
        console.log(data.MESSAGE_TYPE);
        let eventInstance = this.#eventemitters.get(data.MESSAGE_TYPE) || [];
        let evtdata;
        if(data.MESSAGE_TYPE === 'auth') {
            window.localStorage.setItem('socketid', JSON.parse(data.msg).connectionid);
        }
      
        try {
          evtdata =  JSON.parse(data.msg)
        } catch (ex) {
            evtdata = data.msg;
        }

        for (let ev of eventInstance) {
            ev(evtdata);
        }

    }

    #handleOpen() {
        this.#wsock.send(JSON.stringify({ MESSAGE_TYPE: 'auth-message', msg: { userid: this.#options.userid} }));
    }

    #handleClose() {
        //this.#eventemitters = [];
        console.log('connection closed');
    }

    get wsock () {
        return this.#wsock;
    }

    send(msg) {
        const data = typeof msg === 'object' ? JSON.stringify(msg) : msg;
        this.#wsock.send(data);
    }

    emit(evt, msg) {

       
        msg = {msg, MESSAGE_TYPE: evt};
        
        const data =  JSON.stringify(msg);
        console.log(data);
        this.#wsock.send(data);
    }

    on(evt, cb) {
        const cbs =  this.#eventemitters.get(evt)|| [];
        cbs.push(cb);
        this.#eventemitters.set(evt, cbs);

    }
}



export const WsockContext = createContext({});


export const WsockProvider = ({children, url, options}) => {
    if(!gSock) {
        gSock =  new Wsock(url, options);
    }
    return (
        <WsockContext.Provider value={gSock}>{children}</WsockContext.Provider>
    )

}

export const useWsock = () => {
    return useContext(WsockContext);
}

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { UserService } from "../services/rest";
import MenuIcon from "../icons/menu";
import { Link } from "react-router-dom";
import AuthService from "../services/auth-service";
import HomeIcon from "../icons/home";

const RightDropDown = () => {
   

    const [usrdd, setUsrdd] =  useState(false);
    const [theme] = useTheme();
    const [store, dispatch] =  useStore();
    const navigator =  useNavigate();
   
    
    useEffect(() => {
     
        const el = (e) => {e.preventDefault(); setUsrdd(false);}
        if(usrdd) {
         document.addEventListener('click', el);
        } else {
            document.removeEventListener('click', el);
        }
        
        return () => {
            console.log(el);
            document.removeEventListener('click', el);
        }
    }, [usrdd]);

    const logoutUSer = () => {
        window.localStorage.clear();
        dispatch({type: 'reset'});
        navigator('/');
    }

    return (
        <div style={{position:"relative"}}>
                <>{AuthService.UserLoggedIn() && store.user.uid && <div  onClick={(e) => {
                    
                    e.stopPropagation();
                    setUsrdd(true)
                    }} style={{width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: theme.primary, cursor: "pointer", color: theme.onSecondaryContainer, backgroundColor: theme.background}} className="avatar usr-initials">{UserService.GetInitials(store.user.firstname+' '+store.user.lastname)}</div>
                    }
                    {!store.user?.uid && ! AuthService.UserLoggedIn() && <div onClick={(e) => {
                    
                    e.stopPropagation();
                    setUsrdd(true)
                    }} style={{width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', borderColor: theme.primary, cursor: "pointer", color: theme.onSecondaryContainer}} ><MenuIcon/> </div>}
                    </>
                {usrdd ?
                <>
                {store.user.uid &&  <ul className="u-dd" style={{backgroundColor: theme.secondary, color: theme.onSecondary}}>
                    <li style={{cursor: 'pointer'}} onClick={logoutUSer}>Logout</li>
                </ul> }
                {!store.user?.uid  && <ul className="u-dd mobile" style={{backgroundColor: theme.secondary, color: theme.onSecondary, textAlign: "left", minWidth: 200}}>
                    <li style={{paddingLeft: 4}}><Link to='/' style={{display: 'flex', alignItems:  "center"}}><HomeIcon fill={theme.onSecondary} /> Home</Link></li>
                    <li><Link style={{color:theme.onSecondary}} to="register-driver">Sign up to drive</Link></li>
                    <li><Link style={{color:theme.onSecondary}}  to="login">Sign In</Link></li>
                </ul>
                }
                </>
                :null}
       </div>
    )

}

export default RightDropDown;
import { useStore } from "../contexts/app-context";
import { useTheme } from "../contexts/theme-context";
import { KwButton } from "./buttons";
import FormInput from "./form-input"

const ConfirmSelect = ({actionCallback =() =>{}}) => {
    const [theme] = useTheme();
    const [store, dispatch] = useStore();
    const setRemark = (e) => {
        dispatch({type: 'update-ride', payload: {driverremarks: e.target.value}});
    }
    return (
        <div className="ride-info-driver" style={{backgroundColor: theme.surface, padding: 16, color: theme.onSurface, borderTopRightRadius: 8, borderTopLeftRadius: 8}}>
            <h1>Confirrm ride selection</h1>
            <div style={{textAlign: 'left'}}>
                <FormInput 
                    onChange={setRemark} 
                    label="Remarks" 
                    multiline fullwidth 
                    labelColor={theme.onSurface}
                    border = {theme.outline}
                    backgroundColor =  {theme.surface}
                    highlight={theme.primary} 
                    rows={5} 
                    color= {theme.onSurface} />
            </div>
            <div className="app-buttons">
                <KwButton 
                    onClick={() => actionCallback('CONFIRM')} 
                    label="Confirm" 
                    fullwidth
                    color={theme.onPrimary}
                    background = {theme.primary}
                    hoverColor = {theme.primaryContainer}
                    hoverTextColor = {theme.onPrimaryContainer}
                    border = {theme.outline}
                    highlight = {theme.primary}
                    />
                <KwButton 
                    onClick={() => actionCallback('CANCEL')} 
                    className="accent" 
                    label="Cancel"
                    fullwidth
                    color={theme.onPrimary}
                    background = {theme.primary}
                    hoverColor = {theme.primaryContainer}
                    hoverTextColor = {theme.onPrimaryContainer}
                    border = {theme.outline}
                    highlight = {theme.primary}
                     />
            </div>
        </div>
    )
}

export default  ConfirmSelect;
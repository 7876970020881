const Alert = ({text='', severity= 'info'}) => {

   
    return (
        <div className={`alert ${severity}`}>
            <span>{text}</span>
        </div>
    )
}

export default Alert;
import { Strings } from "../settings";

class PayApi {
    static #headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`
    }
    static async RequestPayment(data) {
        try {
            const res = await fetch(`${Strings.api}/payment/request`, {
                method: 'POST',
                headers: this.#headers,
                body: JSON.stringify(data)
            });

            if(!res.ok) {
                throw new Error('Neteork error')
            }

           const raw =  await res.json();
           console.log(raw);
           return raw;

        } catch (ex) {
            throw ex;
        }
    }

    static async GetClientAccessToken() {
        try {
            const res =  await fetch(`${Strings.api}/payment/clientaccesstoken`, {
                headers: this.#headers
            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            const token =  await res.json();
            return token;

        } catch (ex) {
            throw ex;
        }
    }
}

export default PayApi;
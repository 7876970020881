import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useTheme } from "../contexts/theme-context";
import {Strings} from '../settings';
import {useStore} from '../contexts/app-context';
import { PayApi, RideService } from "../services/rest";
import moment from "moment";
import { useEffect, useState } from "react";
import CardPayment from "./card-payment";
import '../styles/card-payment.css';
import SuccessScreen from "./success-screen";
import CashPaymentInfo from "./cash-payment-info";
import { KwButton } from "./buttons";
export const Payments = ({ride, cancelRideCallback, approveCallback}) => {
    const [theme, themedispatcher] = useTheme(); 
    const [store, dispatch] = useStore();
    const [ptype, setPtype] = useState('P');

    useEffect(() => {
        themedispatcher({type: 'toolbar', value: 'wide-static'});
        themedispatcher({type: 'toolbar-width', value: '100%'});

        return () => {
            themedispatcher({type: 'toolbar-width', value: 52})
        }
    }, [])

    const canceRide = (e) => {
        e.preventDefault();
        cancelRideCallback();
    }


    const paymentCompleted = () => {
        approveCallback();
    }

    const cashPaymentActions = (a) => {
        if(a === 'CONFIRM') {
            approveCallback();
        } else {
            setPtype('P');
        }
    }
     console.log(store.config);
   
       let scr = null;
       switch(ptype) {
        case 'P':
            scr = 
        <PayPalScriptProvider options={{ "client-id": store.config.PAYPAL_CLIENT, intent:"authorize" ,components:"buttons"}}>
        <div className="vepp-payments" style={{paddingTop: 72, backgroundColor: theme.background, paddingBottom: 32}}>
            <h1 style={{padding: 16, color: theme.foreground}}  className="title-">Payment</h1>
            <div style={{backgroundColor: theme.secondaryContainer, width: '100%',borderRadius: 16}}>
            <div style={{padding: 16, color: theme.foreground}}><span className="title">Total: {ride.cost.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</span></div>
            <div style={{color: theme.onSecondaryContainer}} className="trip-summary">
                <p>{store.ride?.startdetails?.shortname} to {store.ride?.stopdetails[store.ride.stopdetails.length -1].shortname}</p>
                {store.ride?.package ? <p>Package delivery</p>: null}
                <p>{store.ride?.stopdetails?.length} {store.ride?.stopdetails?.length > 1 ? 'stops' : 'stop'} </p>
            </div>
            </div>
        <ul style={{display: 'flex', flexDirection: 'column', margin: 0, padding: 0, boxSizing:"border-box", color: theme.onBackground}} className="payment-selector" >
            <li style={{ position: "relative", background: theme.onBackground, marginTop: 16, borderRadius: 16, boxSizing: "border-box", padding: 16}}>
               <PayPalButtons 
               createOrder={
                (data, actions) => {
                    return PayApi.RequestPayment({
                                amount: ride.cost
                            }
                            ).then(order=>order.id).catch(ex => console.log(ex));
                }
               }
               onApprove = {(data, actions) => {
                const currpayment = {paymentid: data.orderID, status: 'pending', method: 'Paypal', total: store.ride.cost};
                store.ride.payment = currpayment;

                dispatch({type: 'update-ride', payload: {payment: currpayment}});
                const postdata =  {...store.ride};
                delete postdata.gpx;
                delete postdata.startdetails;
                delete postdata.stopdetails;
                postdata.requestedby = store.user.uid;
                return RideService.PostRide(postdata).then(r => {
                   
                    r.duration = moment.duration(moment().startOf('hour').add(r.duration, 'seconds').diff(moment().startOf('hour'))).asMinutes();
                    dispatch({type: 'update-ride', payload: r});
                    paymentCompleted();;
                 
               })
               .catch(ex => {
                   //setSpin(false);
                   alert('Error processing request');
                  
               });
               /* actions.order.authorize().then(auth => {
                    const authid = auth.purchase_units[0].payments.authorizations[0].id;
                    alert('we have authaurized payment with id '+authid);user
                    approveCallback({authorizeid: authid, method: 'Paypal'})
                });*/
               }}
               style={{ layout: "vertical" , tagline: false, color:  'black', height: 48}}  />
               
            
            </li>
          {/*  <li>
                <KwButton onClick={() =>setPtype('C')}  background={theme.primary} label="Pay by card"  color={theme.onPrimary} hoverColor={theme.primaryContainer} hoverTextColor={theme.onPrimaryContainer} highlight={theme.primary} border={theme.primaryContainer} fullwidth />
            </li>*/ }
            <li>
            <KwButton onClick={() =>setPtype('M')}  background={theme.secondary} label="Pay by cash"  color={theme.onSecondary} hoverColor={theme.secondaryContainer} hoverTextColor={theme.onSecondaryContainer} highlight={theme.secondary} border={theme.secondaryContainer} fullwidth />
            </li>
            <li>
            <KwButton onClick={canceRide}  background={theme.tertiary} label="Cancel"  color={theme.onTertiary} hoverColor={theme.tertiaryContainer} hoverTextColor={theme.onTertiaryContainer} highlight={theme.tertiary} border={theme.tertiaryContainer} fullwidth />
            </li>
        </ul>
        </div>
      </PayPalScriptProvider>
      break;
      case 'C':
        scr =<div className="vepp-payments" style={{overflow: "hidden", position: "relative"}}><CardPayment paymentComplete={() => paymentCompleted()} cancelRide={cancelRideCallback} /></div>
        break;
      case 'M':
        scr = <div className="vepp-payments" style={{overflow: "hidden", position: "relative"}}><CashPaymentInfo actionCallback={(a) => cashPaymentActions(a)} /></div>
        break;
       default:
            scr = null;
            
            }
              
       return scr;
}




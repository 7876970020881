import { useEffect, useRef, useState } from "react";
import { useTheme } from "../contexts/theme-context"


const Snack = ({text='', position='bottom', duration=5000, show}) => {
    const [theme] = useTheme();
    const [snack, setSnack] = useState({show: false, text, severity: 'info'});
    const snackRef = useRef(null);
   

    useEffect(() => {
        show.current = {
            show: (message, severity='info')=> {
                setSnack({show: true, text: message, severity});
            }
        
        }
    }, [show])

    useEffect(() => {
        let tmout;
        if(snack.show === true) {
            tmout = setTimeout(() => {
                setSnack({show: false, text: ''})
                clearTimeout(tmout);
                tmout = null;
            }, duration);
        }
       

    }, [snack]);

    useEffect(() => {
        if(snackRef.current) {
            snackRef.current.style.marginLeft = `${getLeftPos() * -1}px`;
        }
    });

    const getPos = () => {
        if(position === 'top') {
            return {top: 0}
        } else {
            return {bottom: 0}
        }
    }

    const getLeftPos = () => {
        console.log(snackRef);
         const width = snackRef.current.offsetWidth;
         console.log(width);
         return width /2;
    }


   
    return snack.show ? (
        <div ref={snackRef} style={{background: theme.background, 
                     animation: position === 'top' ? 'slidedown .3s' : 'slideup .3s',
                     margin: '0 auto',
                     left: '50%',
                     padding: 16,
                     color: snack.severity === 'info' ? theme.info : snack.severity === 'error' ? theme.error : snack.severity==='warning' ? theme.warning: theme.foreground,
                     position: 'absolute',
                     ...getPos()}}>
            <span>{snack.text}</span>
        </div>
    ) : null;
}
export default Snack;
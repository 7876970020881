const FormatEmail = () => {
    
}


const FormatPhoneNumber = (phoneno) => {
   
        let cleaned = ('' + phoneno).replace(/\D/g, '')
        let countrycode='';
        if(cleaned.length > 10) {
        
            const delcount =  cleaned.length - 10
            countrycode =  cleaned.slice(0, delcount);
            cleaned = cleaned.slice(delcount).replace(/\D/g, '');
        }
        console.log(cleaned.length, cleaned,' ', countrycode)
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        console.log(match);
        if (match) {
          return countrycode + ' (' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
      
}


export {FormatEmail, FormatPhoneNumber}
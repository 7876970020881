import '../styles/login-page.css';
import { Link } from "react-router-dom";
import { useTheme } from '../contexts/theme-context';
import { useEffect } from 'react';
const LoginScreen = () => {
    const [theme, dispatch] = useTheme();
    useEffect( () => {
        dispatch({type: 'toolbar', value: 'wide-static'});
    }, []);

    return (
        <div className="login-page" style={{ backgroundColor: theme.background, color: theme.foreground }}>
            <div className='landing-container'>
                <div className="intro">
                    <h1 className="large-title">Get arround with Vepp</h1>
                    <div style={{marginTop: 32}}>
                        <p>Go anywhere anytime.</p>
                        <p></p>
                    </div>
                    <div className="login-type-buttons" style={{marginTop: 32}}>
                        <div>
                            <Link to="/login"> <button className="accent">Sign in</button></Link>
                        </div>
        
                    </div>
                    <div>
                        Dont have an account? <Link style={{textDecoration: 'none'}} to='/register-rider'>Sign up</Link> to use our service.
                    </div>
                </div>
                <div style={{ position: 'relative' }}>

                    <div className='landing-bg'>
                        <img src='images/bg-taxi-sign.png' alt='vepp app' />
                    </div>
                </div>
            </div>
        </div>);
}
export default LoginScreen;
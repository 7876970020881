import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../contexts/theme-context";
import {useStore} from "../contexts/app-context";
import RightDropDown from "./right-dropdown";
import MenuIcon from "../icons/menu";

const AppTolbar = ({wide=false, extra=false}) => {
  const [store] = useStore();
 
  const [theme, dispatch] =  useTheme()

  useEffect(() => {
   // dispatch({type: 'toolbar', value:'normal'});
  },[])
    return (
     
     <>
      { theme.toolbar !== 'none' && 
      <> 
      <div className="mobile-toolbar" style={{width: theme.toolbarWidth, alignItems: 'center', height: 64, right: theme.toolbarWidth === '100%' ? 0 : 8, backgroundColor: theme.backgroundVariant, top: 0, padding: 8, boxSizing: "border-box"}}>
        {theme.toolbarWidth === '100%' &&  <span className="space"></span>}
      <div className="bar-right" style={{justifySelf: 'right'}}><RightDropDown /></div>
      </div>
      <div className={theme.toolbar ==='wide-static' ? "primary app-bar static" : "primary app-bar"} style={{backgroundColor: theme.backgroundVariant, zIndex: 9999}}>
        <div className={theme.toolbar ==='wide' ? "app-bar-body-wide": "app-bar-body"}>
        <div className="bar-left" style={{alignItems: "center"}}>
         <span className="brand title" style={{height: 48}}><Link to="/" style={{display: 'flex', alignItems: 'center'}}><div style={{padding: 4, borderRadius:'50%', overflow: "hidden", height: 48, width: 48, boxSizing: "border-box", display: "flex", alignItems: 'center', justifyContent: 'center', border: `2px solid ${theme.primary}`, backgroundColor: theme.primary, textAlign: "center", marginRight: 8}}><img src="logo192.png"  height={38} alt="vepp" style={{ boxSizing: "border-box"}}/></div> Vepp</Link></span>
          {extra ?<div className="links">
          <span>Rider</span>
          <span>Driver</span>
          </div>:null }
          {store.driverid ?<div className="links"> <span><Link to="ridelist" state={{driverid: store.driverid}}>Earnings</Link></span></div>: null}
        </div>
        <div className="bar-right">
          {!store.user.uid && <div>
              <span style={{textTransform: 'uppercase', fontSize: 14}}><Link to="/register-driver">Become a driver</Link></span>
            </div>}
          {store?.user?.uid ? <>
            <RightDropDown />
            
          </> :
          <>
          {
          theme?.toolbaroptoptions?.signinbtns ?
          <>
          <span>Sign Up</span>
          <span><Link to="login-select">Sign In</Link></span>
          </>
          :null
          } 
          </> 
          }
        </div>
        </div>
      </div>
      </>
     }

    </>
    )
}

export default AppTolbar;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

export const KwButton = ({label, border, color='#fefefe', background='#CCC', icon, hoverTextColor, highlight = '#000', hoverColor, onClick=(e) => {}, fullwidth=false, children, style={}}) => {
    const btnRef  = useRef(null);
    const btnRefContainer  = useRef(null);
    //const btnStylesRef = useRef({background, border, hoverColor, hoverTextColor, highlight, color})
    
    
    useEffect(() => {
       
        if(btnRefContainer.current && btnRef.current) {
            btnRef.current.onfocus = () => {
               
                btnRefContainer.current.style.borderColor = highlight;
               // btnRefContainer.current.style.backgroundColor = background;
                btnRef.current.style.borderColor = highlight;
            }
            btnRef.current.onblur = () => {
                if(btnRefContainer.current && btnRef.current) {
                btnRef.current.style.borderColor = border;
                
                btnRefContainer.current.style.borderColor = 'transparent';
                btnRef.current.style.borderColor = border;
               
               // btnRefContainer.current.style.backgroundColor = background;
                }
                
           }
            btnRef.current.onmouseover = () => {
                
                btnRef.current.style.backgroundColor = hoverColor || background;
                btnRef.current.style.color = hoverTextColor || color;
            }

            btnRef.current.onmouseout = () => {
                btnRef.current.style.backgroundColor = background;
                btnRef.current.style.color = color;
            }
        }
    }, [btnRef, btnRefContainer, color, highlight, background, hoverTextColor, hoverColor, border])
  
    return (
        <div ref={btnRefContainer}  className="kw-button" style={{width: fullwidth ? '100%': 'auto'}}>
             <button onClick={onClick} ref={btnRef} style={{border: `1px solid ${border? border : 'transparent'}`, backgroundColor: background, color: color, fontSize: '1.05rem', boxSizing: "border-box", width: fullwidth ? '100%': 'auto', display: 'flex', alignItems: "center", justifyContent: 'center', ...style}}>
                {label}  {children && <div style={{display: 'inline-block', marginLeft: 8}}>{children}</div>}
            </button>
        </div>
   )
}

export const KwIconButton = ({label, border, color='#fefefe', icon, background='#CCC', highlight = '#000', hoverColor="#aaa", onClick = (e) => {}}, hoverTextColor="#e0e0e0") => {
    const btnRef  = useRef(null);
   
    useEffect(() => {
       
        if( btnRef.current) {
            btnRef.current.onfocus = () => {
               
                btnRef.current.style.borderColor = highlight;
            }
            btnRef.current.onblur = () => {
                if(btnRef.current) {
                btnRef.current.style.borderColor = 'transparent';
                btnRef.current.style.color = color;
                }
               // btnRef.current.style.backgroundColor = background;
            }
            btnRef.current.onmouseover = () => {
                btnRef.current.style.backgroundColor = hoverColor;
                btnRef.current.style.color = hoverTextColor;
            }

            btnRef.current.onmouseout = () => {
                btnRef.current.style.backgroundColor = background;
                btnRef.current.style.color = color;
            }
        }
    }, [btnRef])
    return (<button onClick={onClick} ref={btnRef} style={{border: '3px solid transparent', backgroundColor: background, color: color, fontWeight: 'bold', fontSize: '1.05rem', boxSizing: "border-box", borderRadius: '50%', width: 48, height: 48, cursor: "pointer"}}>
        {icon}
    </button>)
}
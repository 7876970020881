import { Strings } from "../settings"

const UserApi = () => {
    const RegisterUser = (userdata) => {
        return fetch(`${Strings.api}/user`, {
            method: 'POST',
            headers: {"Content-Type": 'application/json'},
            body: JSON.stringify(userdata)
        }).then(res => {
            if (!res.ok) {
                throw new Error('Network error')
            }
            return res.json();
        });
    }

    const ProcessCode = (userid, code) => {
        return fetch(`${Strings.api}/user/processcode/${userid}`, {
            method: 'PATCH',
            body: JSON.stringify({code}),
            headers: {"Content-Type": 'application/json'},
        }).then(res => {
            if(!res.ok) {
                throw new Error('Network error');
            }
            return res.json();
        });

    }

    const ResendCode = (userid) => {
        return fetch(`${Strings.api}/user/generatecode/${userid}`, {
            method: 'PATCH',
            body: JSON.stringify({}),
            headers: {"Content-Type": 'application/json'},
        }).then(res => {
            if(!res.ok) {
                throw new Error('Network error');
            }
            return res.json();
        });
    }

    const GetUser = (uid) => {
        return fetch(`${Strings.api}/user/${uid}`, {
            headers: {
                "Content-Type": 'application/json'
            }
        }).then(res => {
            if(res.ok) {
                return res.json();
            }
        });
    }

    const SavePhoto = (uid, data) => {
        return fetch(`${Strings.api}/user/profilepic/${uid}`, {
            method: 'PATCH',
           
            body: data
        }).then(r => {
            if(!r.ok) {
                throw new Error('Network error');
            }
            return r.json();
        });

    }

    const UpdateUser = (uid, data) => {
    
        return fetch(`${Strings.api}/user/${uid}`, {
            method: 'PATCH',
            headers: {"Content-Type": 'application/json'},
            body: JSON.stringify(data) 
        }).then(r => {
            if(!r.ok) {
                throw new Error('Network error');
            }
            return r.json();
        });
    }

    const LoginUser = (data) => {
        
        return fetch(`${Strings.api}/user/login`, {
            method: 'POST',
            headers: {"Content-Type": 'application/json'},
            body: JSON.stringify(data)
        }).then(res => {
            if(!res.ok) {
                throw new Error('Network error');
            }
            return res.json();
        });
    }

    const GetInitials = str => {
        if(!(str || '').trim()) {
            return;
        }
        console.log(str);
        const names =  str.split(' ');
        console.log(names);
        const first = names.shift();
        
        const last = names.pop();
        console.log(first, last)
        return `${first.substring(0,1)} ${last.substring(0,1)}`;
    }

    const GetByEmail = async email =>{
        try {
            const res =  await fetch(`${Strings.api}/user/email/${email}`);
            if(!res.ok) {
                throw new Error('oops something went wrong')
            }
            return res.json();

        } catch (ex) {
            throw ex;
        }
    }

    const ForgotPassword = async email => {
        try {
            const res =  await fetch(`${Strings.api}/user/forgetpassword/${email}`, {
                method: 'PATCH', 
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify({email})});
            if(!res.ok) {
                throw new Error('Oops somethig wrnt wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }

    const GenerateResetLink = async (id, data) => {
        try {
            const res  = await fetch(`${Strings.api}/user/resetcode/${id}`,  {
                method:  'PATCH',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            });
            if(!res.ok) {
                throw new Error('Oops. something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }


    const CheckResetCode = async (id, code) => {
        try {
            const res = await fetch(`${Strings.api}/user/validateresetcode?id=${id}&code=${code}`);
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }

    const ResetPassword = async (id, data) => {
        try {

            const res = await fetch(`${Strings.api}/user/resetpassword/${id}`, {
                method: 'PATCH',
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(data)

            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();
        } catch (ex) {
            throw ex;
        }

    }

    return Object.freeze({
        RegisterUser,
        ProcessCode,
        ResendCode,
        GetUser, 
        SavePhoto,
        UpdateUser,
        LoginUser,
        GetInitials,
        GetByEmail,
        ForgotPassword,
        CheckResetCode,
        GenerateResetLink,
        ResetPassword
    });


}

export default UserApi;
import { useTheme } from '../contexts/theme-context';
import FormInput  from './form-input';
import {PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider, usePayPalHostedFields} from '@paypal/react-paypal-js';
import { useEffect, useRef, useState } from 'react';
import { Strings } from '../settings';
import { useStore } from '../contexts/app-context';
import '../styles/card-payment.css';
import { PayApi, RideService } from '../services/rest';
import Spinner from './spinner';


const CUSTOM_FIELD_STYLE = {"border":"1px solid #e0e0e0","boxShadow":"2px 2px 10px 2px rgba(0,0,0,0.1)","height": "62px", "padding": "12px 16px", "boxSizing": "border-box"};
const INVALID_COLOR = {
	color: "#dc3545",
};

const SubmitPayment = ({messageCallback, completeCallback, cancelRide}) => {
    const hostedFields = usePayPalHostedFields();
    const cardHolder =  useRef(null);
    const [store, dispatch] = useStore();
    const [processing, setProcessing] = useState(false);
    const [completed, setCompleted] =  useState(false);
    const [theme] = useTheme();

    useEffect(() => {
        if(!processing && completed) {
            completeCallback();
        }
    }, [processing, completed])

    const cancelClicked = (e) => {
        e.preventDefault();
        cancelRide();
    }
    
    const submitHandler = (e) => {
        e.preventDefault();
       
        if(typeof hostedFields?.cardFields?.submit !== 'function') {
            messageCallback('Problem processing payment. Try again')
            return;
        }
        const isFormInvalid = Object.values(hostedFields.cardFields.getState().fields).some(f => !f.isValid) || !cardHolder?.current?.value
        if(isFormInvalid) {
            messageCallback('Form is invalid');
            return;
        }
        setProcessing(true);
        hostedFields.cardFields.submit({
            cardholderName: cardHolder?.current.value
        }).then(data => {
           
            const currpayment = {paymentid: data.orderId, status: 'pending', method: 'Card', total: store.ride.cost};
            
            store.ride.payment = currpayment;
            dispatch({type: 'update-ride', payload: {payment: currpayment}});
            RideService.PostRide({...store.ride, requestedby: store.user.uid}).then(d => {
                setCompleted(true);
              
            }).catch(err => {
                messageCallback('Oops!! Something went wrong while processing your request')
            }).finally(() => {
                setProcessing(false);
            }
                )
        }).catch(err=> {
            setProcessing(false);
            messageCallback('Oops!! Something went wrong while processing your payment');
        })
    }

    return(
        <>
        <label htmlFor='card-hoilder' title="this represents the full name as shown on the card">Card holder name
        </label>
            <input id="card-holder"  ref={cardHolder} className="card-field" style={{...CUSTOM_FIELD_STYLE, outline: 'none', width:"100%", padding: "12px 16px", height: 64, fontSize: 16}} type="text" placeholder='Full name' />
        <div className="app-buttons" style={{alignItems: "center", marginTop: 16, justifyContent:"space-evenly"}}>
        <button  style={{height: 64, flex: 1, marginRight: processing ? 0 : 24}} className= {`accent btn${processing?"": " btn-primary"}`} onClick={submitHandler} >{processing ? <Spinner size={24} color={theme.primary} /> : 'Pay'}</button>
        {!processing ? <button onClick={cancelClicked} className="accent" style={{height: 64, flex:1}} >Cancel</button>: null}
        </div>
        </>
    )
}

const CardPayment = ({paymentComplete, cancelRide}) => {
    const [theme] =  useTheme();
    const [clientToken, setClientToken] = useState(null);
    const [store, dispatch] = useStore();
    const [message, setMessage] =  useState('');
   
    useEffect(() => {
            PayApi.GetClientAccessToken().then(data => {
            console.log(data.token);
            setClientToken(data.token);
        
            
        }).catch(ex => {
            console.log(ex);
        })
    }, [])
    
    return (
        clientToken ? (
        <div className="card-payment" style={{marginTop: 108, backgroundColor: theme.surfaceVariant, color: theme.onSurfaceVariant}}>
            <h1>Pay by card </h1>
            <div style={{color: theme.accent}}></div>
       <PayPalScriptProvider options={{
        "client-id": store.config.PAYPAL_CLIENT,
        components: 'buttons,hosted-fields',
        "data-client-token" : clientToken,
         intent:"authorize",
         vault: false
    
         }} >
         <PayPalHostedFieldsProvider 
         styles={{".valid":{"color":"#28a745"},".invalid":{"color":"#dc3545"},"input":{"font-family":"monospace","font-size":"16px"}, ":focus":{"outline":"2px solid #01c38e"}, "label": {"margin-top":"16px"}}}
         createOrder={() => {
            PayApi.RequestPayment({amount: 10.00}).then(order =>  order.id
            ).catch(err => {
                
                setMessage('Oops!! something went wrong. Could not complete your request')
                console.log(err);
            })
         }}
        >
          <label htmlFor="card-number">
            Card Number
            <span style={INVALID_COLOR}>*</span>
           </label>
          <PayPalHostedField
              id="card-number"
              className="card-field"
              style={CUSTOM_FIELD_STYLE}
              hostedFieldType="number"
              options={{selector: "#card-number", placeholder: "4111 1111 1111 1111"}}
          />
           <label htmlFor="cvv">
            CVV<span style={INVALID_COLOR}>*</span>
           </label>
          <PayPalHostedField
                style={CUSTOM_FIELD_STYLE}
                className="card-field"
                id="cvv"
                hostedFieldType="cvv"
                options={{ selector: "#cvv" ,placeholder: "123", maskInput: true}}
            />
           <label htmlFor="expiration-date">
             Expiration Date
             <span style={INVALID_COLOR}>*</span>
            </label>
           <PayPalHostedField
                id="expiration-date"
                className="card-field"
                hostedFieldType="expirationDate"
                style={CUSTOM_FIELD_STYLE}
                options={{
                        selector: "#expiration-date",
                        placeholder: "MM/YYYY",
                }}
                />
           <SubmitPayment messageCallback={setMessage} completeCallback = {() => paymentComplete()} cancelRide={cancelRide}  />
           
        </PayPalHostedFieldsProvider>
       
        </PayPalScriptProvider>   </div>): <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center", height: "calc(100vh - 80px)"}}><Spinner size={96} color={theme.primary} /></div>
       
        
    );
}

export default CardPayment;
//import logo from './logo.svg';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import './App.css';
import AppTolbar from './components/app-toolbar';

import { AppProvider, useStore } from './contexts/app-context';
import Home from './pages/home';
import LoginScreen from './pages/login-page';
import RegisterDriver from './pages/register-driver';
import RegisterRider from './pages/register-rider';
import RideSelect from './pages/ride-select';
import RiderLogin from './pages/rider-login';
import RiderProfile from './pages/rider-profile';
import DriverProfile from './pages/driver-profile';
import AuthService from './services/auth-service';
import { ConfigApi, UserService } from './services/rest';
import DriverPage from './pages/driver-page';

import { Strings } from './settings';
import DriverRides from './pages/driver-rides';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Trips from './pages/trips';
import {useTheme} from './contexts/theme-context'
import LoginDriver from './pages/login-driver';

function App() {

  const [widetoobar, setWideToolbar] = useState(false);
  const [theme] = useTheme();
  const [store, dispatch] =  useStore();

  const GetConfig = useCallback(async() => {
   if(AuthService.UserLoggedIn() && store.user.uid) {
        const conf =  await ConfigApi.GetConfig();
        dispatch({type: 'set-config', payload: conf});
   }

  }, [store.user])

  useEffect(() => {
       GetConfig();
  }, [GetConfig])

  
   
  return (
   <div className="App" style={{backgroundColor: theme.background, width: '100%', height: '100vh'}}>
      <AppTolbar wide={widetoobar}/ >  
      { <div className="content" style={{backgroundColor: theme.background, height: theme.toolbar === 'none' ? '100vh' : 'auto'}}>
         <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/ride" element={<RideSelect />}/>
         <Route path="/home" element={<LoginScreen toolbarOptions={setWideToolbar }/>} />
         <Route path="/login" element={<RiderLogin toolbarOptions={setWideToolbar }/>} />
         <Route path="/driver-login" element={<LoginDriver toolbarOptions={setWideToolbar }/>} />
         <Route path="/register-rider" element={<RegisterRider toolbarOptions={setWideToolbar} />} />
         <Route path="/register-driver" element={<RegisterDriver toolbarOptions={setWideToolbar} />} />
         <Route path="/driver" element={<DriverPage  />} />
         <Route path="/ridelist" element={<DriverRides toolbarOptions={setWideToolbar} />} />
         <Route path="/forget-password" element={<ForgotPassword />} />
         <Route path="reset-password" element={<ResetPassword />} />
         <Route path="trips" element={<Trips />} />
         <Route path="driver-profile" element={<DriverProfile toolbarOptions={setWideToolbar} />} />
         </Routes>
      </div>
       }
    </div>
  
  
  );
}

export default App;

import { useCallback, useEffect, useState } from "react";
import { useTheme } from "../contexts/theme-context";
import FormInput from "./form-input";
import { VehicleService } from "../services/rest";
import { useStore } from "../contexts/app-context";
import Spinner from "./spinner";
import Alert from "./alert";

const VehicleDetails = () => {

    const [vehicle, setVehicle] =  useState(null);
    const [store] = useStore();
    const [theme] = useTheme();
    const [pstate, setPstate] = useState({spin: false, alert: {visible: false, text:'', type:'info'}})


    const getVehiclesForDriver = useCallback(async (contr) => {
        try {
            const vehicles = await VehicleService.ListVehiclesForDriver(store.driverid, contr.signal);
            console.log(vehicles);
            setVehicle(vehicles[0]);
        } catch (ex) {
            console.log(ex);
        }

        return () => {
            if(contr) {
                contr.abort();
            }
        }

    }, [store.driverid])

    useEffect(() => {
        if(store.driverid) {
            const contr =  new AbortController();
            getVehiclesForDriver(contr);
        }

    }, [getVehiclesForDriver, store.driverid]);

    const valueChange = (e) => {
        const {value, name} =  e.target;
        setVehicle(v => ({...v, [name]: value}));
    }


    const updateVehicle = async data => {
        try {
            const contr =  new AbortController();
            const res =  await VehicleService.UpdateVehicle(vehicle._id ,data, contr.signal)
            res._id =  res.vehicleid;
        
            setVehicle(res);

        } catch (ex) {
            throw ex;
        }
    } 

    const addVehicle = async (data) => {
        try {
            const contr =  new AbortController();
            const res = await VehicleService.AddVehicle(data, contr.signal)
            res._id = res.vehicleid;
            setVehicle(res);
            
        } catch (ex) {
           throw ex;
        }
    }

    const saveClicked =  async e => {
        e.preventDefault();
        setPstate(p => ({...p, spin:  true}));
        try { 
        const data =  {
            make: vehicle.make,
            model: vehicle.model,
            color: vehicle.color,
            registration: vehicle.registration,
            seats: parseInt(vehicle.seats),
            driverid: store.driverid
        }
        if(vehicle._id) {
              await updateVehicle(data);
              setPstate(p => ({...p, alert: {text: 'Vehicle updated', type: 'info', visible: true}}))
        } else {
            await addVehicle(data);
            setPstate(p => ({...p, alert: {visible: true, text: 'Vehicle added', type: 'info'}}))
        }
    } catch (ex)  {
        setPstate(p => ({...p, alert: {text: ex.message, type: 'error', visible: true}}))
    } finally {
        setPstate(p => ({...p, spin:  false}));
    }
}


   
    return (
        <div>
            <h3>Vehicle Information</h3>
            {pstate.alert.visible && <div style={{marginBottom: 16}}><Alert severity={pstate.alert.type} text={pstate.alert.text} /> </div>}
            <form>
            <div className="form-field">
                    <FormInput onChange={valueChange} name="make" fullwidth label="Make (eg. Toyota)" backgroundColor={theme.background} value={vehicle?.make} />
                </div>
                <div className="form-field">
                    <FormInput onChange={valueChange} name="model" fullwidth label="Model (eg Prius)" backgroundColor={theme.background} value={vehicle?.model} />
                </div>
                <div className="form-field">
                    <FormInput name="color" onChange={valueChange} fullwidth label="Color" backgroundColor={theme.background} value={vehicle?.color} />
                </div>
                <div className="form-field">
                    <FormInput name="registration" onChange={valueChange} fullwidth backgroundColor={theme.background} label="Registration #" value={vehicle?.registration} />
                </div>
                <div>
                    <FormInput name="seats" onChange={valueChange}  type="number" backgroundColor={theme.background} label="Seats" value={vehicle?.seats} />
                </div>
                <div className="app-buttons">
                    <button onClick={saveClicked}  style={{backgroundColor: theme.primary, color: theme.onPrimary, display: "flex", alignItems: "center", justifyContent: "center"}} >Save  {pstate.spin && <div style={{height: 36, marginLeft: 8}}><Spinner size={24} /></div>}</button>
                </div>
            </form>
         
           
        </div>
    );
}
export default VehicleDetails;
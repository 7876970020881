import { createContext, useContext, useReducer } from "react";
import { accent, primary } from "../colors";

const Theme = {

    light: {
        background: '#eeeeee',
        foreground: '#444',
        surface: '#191c1a',
        onSurface: '#e1e3df',
        accent: accent,
    },

    dark: {
        background: "#191c1a",
        foreground: "#e1e3e0",
        onBackground: '#e1e3e0',
        accent: accent,
        info: '#fefefe',
        warning: 'orange',
        primary: '#4cdeb0',
        onPrimary: '#003829',
        primaryContainer: '#00513c',
        onPrimaryContainer: '#6dfbcb',
        secondary: '#b3ccc0',
        onSecondary: '#1e352c',
        secondaryContainer: '#354b41',
        onSecondaryContainer: '#cee9db',
        tertiary: '#a7cce0',
        onTertiary: '#0a3445',
        tertiaryContainer: '#264b5c',
        onTertiaryContainer: '#c2e8fd',
        error: '#ffb4ab',
        onError: '#690005',
        errorContriner: '#93000a',
        onErrorContainer: '#ffdad6',
        surface: '#191c1a',
        onSurface: '#e1e3e0',
        outline: '#89938d',
        surfaceVariant: '#404944',
        onSurfaceVariant: '#bfc9c2',
        neutral: '#8e918e',
        backgroundVariant: 'rgb(39,39,39)',
        surface1: 'rgb(76 222 176 / 0.05)',
        surface10: '#141316'

        
    }
}


const initialThemeStste = {...Theme.dark, toolbar: 'normal', toolbarWidth: '100%'}

export const ThemeContext  = createContext({
    state: initialThemeStste,
    dispatch: () => null
});



const themeReducer = (state, action) =>{
    switch (action.type) {
        case 'theme':
            return {...state, theme: Theme[action.value]}
        case 'toolbar':
            return {...state, toolbar: action.value}
        case 'toolbar-width':
            return {...state, toolbarWidth: action.value}
        default:
            return state;
    }
}


export const ThemeProvider = ({children, theme, toolbar='wide-static', toolbarWidth ='100%'}) => {
    const [state, dispatch] =  useReducer(themeReducer, {...initialThemeStste, ...Theme[theme], toolbar, toolbarWidth});
    return (
        <ThemeContext.Provider value={[state, dispatch]}>{children}</ThemeContext.Provider>
    )
}

export const useTheme = () =>  {
    return useContext(ThemeContext);
}
import { useEffect, useState } from "react"
import { RideService } from "../services/rest"
import {useSearchParams, useLocation} from 'react-router-dom';
import { useTheme } from "../contexts/theme-context";
import moment from 'moment';

const DriverRides =  () => {
    const [rides, setRides] = useState([{origindetails: {shortname: 'Bagatelle'}, stopdetails: [{shortname: 'Grand Bay'}], pickupdatetime: new Date(), duration: 20, distance: 4500, cost: 25.00}])
 const loc = useLocation();
 const rider = loc.state.driverid;
 const [theme] = useTheme();

 useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal; 
        RideService.GetRideHistory(rider, 1, signal).then(r => {
            console.log(r);
           // setRides(r);
        }).catch(ex => {
            console.log(ex);
        });
        return () => {
            controller.abort();
        }
    }, []) 

    return (
        <div className="driver-ride-list">
            <h1 className="title" style={{textAlign: "left", color: theme.foreground}} >Rides completed</h1>
            <div className="kwtable" style={{borderRadius: 16, borderColor: theme.outline}}>
            <table className="driver-ride-table" style={{color: theme.onSurface, background: theme.surface}}>
                <thead style={{backgroundColor: theme.surfaceVariant, color: theme.onSurfaceVariant}}>
                    <tr style={{backgroundColor: theme.surfaceVariant, border: 'none'}}>
                        <th style={{borderRightColor: theme.outline}}>Start</th>
                        <th style={{borderRightColor: theme.outline}}>Final stop</th>
                        <th style={{borderRightColor: theme.outline}}>Date</th>
                        <th style={{borderRightColor: theme.outline}}>Duration</th>
                        <th style={{borderRightColor: theme.outline}}>distance</th>
                        <th style={{textAlign: "right"}}>Earnings</th>
                    </tr>
                </thead>
                <tbody>
                    {rides?.map(r => (
                     <tr>
                        <td style={{borderRightColor: theme.outline}}>{r.origindetails.shortname}</td>
                        <td style={{borderRightColor: theme.outline}}>{r.stopdetails[r.stopdetails.length -1].shortname}</td>
                        <td style={{borderRightColor: theme.outline}}>{moment(r.pickupdatetime).format('DD-MM-YYYY HH:mm')}</td>
                        <td style={{borderRightColor: theme.outline}}>{r.duration.toFixed(0)} Minutes</td>
                        <td style={{borderRightColor: theme.outline}}>{(r.distance / 1000).toFixed(2)} KM</td>
                        <td style={{textAlign: "right"}}>{(0.70 * r.cost).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</td>
                     </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    )
}
export default DriverRides
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {ThemeProvider} from './contexts/theme-context';
import { AppProvider } from './contexts/app-context';
import { WsockProvider } from './contexts/Wsock';
import { Strings } from './settings';

ReactDOM.render(
  <React.StrictMode>
     <AppProvider>
      <WsockProvider url={Strings.socketurl} options={{userid: window.localStorage.getItem('uid')}}>

     <ThemeProvider theme={'dark'} toolbarWidth='100%'>
    <BrowserRouter>
    <App />
    </BrowserRouter>
   </ThemeProvider>
   </WsockProvider>
   </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

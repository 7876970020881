
import '../styles/ride-select.css';

import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { RideService, UserService } from '../services/rest';
import RIdeReducer, { initialRideState } from '../services/ride-reducer';
import moment from 'moment';
import { useTheme } from '../contexts/theme-context';
import { Payments } from '../components/payments';
import RideSumary from '../components/ride-sumary';
import RiderMap from '../components/rider-map';
import { useStore } from '../contexts/app-context';
import CardPayment from '../components/card-payment';
import SuccessScreen from '../components/success-screen';
import { Link } from 'react-router-dom';
import RightDropDown from '../components/right-dropdown';
import Spinner from '../components/spinner';




const RideSelect = ({toolbarOptions}) => {
   
    const [store, dispatch]= useStore()
    
    //const [ride, dispatchRide] = useReducer(RIdeReducer, initialRideState);
  
    const [estimating, setEstimating] = useState(false);
    const estimatingRef =  useRef(estimating);
    const [spin, setSpin] =  useState(false);
  
    const [theme, dispatchTheme] =  useTheme();
    const userid = store?.user.uid;
   
    const [showmap, setShowMap] = useState(true);
    const [rightpage, setRightPage] = useState(null);
    const [leftview, setLeftview] =  useState('A');
    const [isactive, setIsActive] =  useState(false);
    const [show, setShow] =  useState(false);


    useEffect(() => {
        dispatchTheme({type:'theme', value: 'dark'});
        dispatchTheme({type: 'toolbar', value: 'none'});
        
    }, []);


    const GetActiveRide = useCallback(async () => {
        try {
            const res =  await RideService.GetActiveRideRider(store.user.uid);
            
            if(res) {
                dispatch({type: 'update-ride', payload: {...res}});
                setIsActive(true);
                setLeftview('S');
            }

        } catch (ex) {
            console.log(ex);
        } finally {
            setShow(true);
        }
    }, [store.user])

    useEffect(() => {
        if(store.user?.uid) {
            GetActiveRide();
        }

    }, [store.user, GetActiveRide])


   
    const RequestRide = () => {
       
        setSpin(true);
        const pakage = store.ride.package;
        const ridedata = {...store.ride, requestedby: userid, package: {...pakage, width: parseFloat(pakage.width), length: parseFloat(pakage.length), depth: parseFloat(pakage.depth), value: parseFloat(pakage.value), weight: parseFloat(pakage.weight) }};
        RideService.PostRide(ridedata).then(r => {
            
             r.duration = moment.duration(moment().startOf('hour').add(r.duration, 'seconds').diff(moment().startOf('hour'))).asMinutes();

            dispatch({type: 'update-ride', payload: r});
           // const url = 'data:application/xml,' + encodeURIComponent(r.gpx);
           // console.log(url);
           setRightPage('MAP');
           setShowMap(true);
           setSpin(false);
        }).catch(ex => {
            setSpin(false);
           
        });
    }

    const paymentApproved = (paydata) => {
        setIsActive(true);
       setShowMap(true);
       setLeftview('S');
       setRightPage('MAP');
    }



    const GetRideCost = (id) => {
       
        RideService.GetCost(id).then(r => {

            dispatch({type: 'update-ride', payload: r});
            setEstimating(false);
            estimatingRef.current = false;
        }).catch(ex => {
            setEstimating(false)
            estimatingRef.current = false;
        });
    }

    const rideCancled = () => {
       
        setIsActive(false);
        setRightPage('MAP');
        setShowMap(true);
        setLeftview('D');
        dispatch({type: 'reset-ride'});

    }

 

    
    const confirmClicked = () => {
       // e.preventDefault();
        setRightPage('PAYMENT');
        setShowMap(false);
        
    }

    let left_content = null;
    let right_content = null;

    switch(rightpage) {
            case 'PAYMENT':
                right_content = <Payments ride={store.ride} cancelRideCallback = {rideCancled} approveCallback={paymentApproved} currentView={leftview}/>
                left_content= null
                break;
            default:
                right_content = null;
                left_content = null;
    }





   /* switch (rightpage) {
        case 'PAYMenT':
            <RideSumary />
            break;
        default:
          
            break;
    }
    */


   
   let pbody = null;
    
    switch (showmap) {
      case true :
      pbody = <RiderMap ride={store.ride} updateRide = {(r) => dispatch({type: 'update-ride', payload: r})} confirmRide={confirmClicked} leftView={leftview} changeLeftView={(l) => setLeftview(l)}  active={isactive} rideCanceledRider={rideCancled} />
    break;
    case false:
           pbody =
           <div className="no-map" style={{height: theme.toolbar === 'none' ? '100vv' : 'calc(100vh - 72px)'}}>
             {left_content ?  <div className="selection-form " style={{background: theme.background, color: theme.foreground, position: "relative", justifyContent: "flex-start", alignItems: 'center'}} >
               <div className="app-bar map-bar">
                       <div className="app-bar-body-wide" style={{alignItems: 'center', pointerEvents: 'auto'}}>
                           <div className="title bar-left">
                               <span className="brand spacer"><Link to="/">VEPP</Link></span>
                            </div>
                            <RightDropDown />
                        </div>
                </div>
                <div className="left-content">
                {left_content}
                </div> 
              </div>
                   
            : null } 
        <div className="right-content" style={{flex: '1 1 auto', backgroundColor: theme.background}}>
            {right_content}
        </div>
        </div>
    break;
    default:
        pbody = null;
    }

   return show ? pbody : <div style={{height: 80, width: 80, left: '50%', top: '50%', position: 'absolute',marginTop: -40, marginLeft: -40, backgroundColor: theme.background}}><Spinner /></div>
    
}

export default RideSelect;
import { useEffect, useRef, useState } from 'react';
import VisibilityIcon from '../icons/visibility';
import '../styles/form-fields.css';
const FormInput = ({color = '#e0e0e0', 
                    labelColor ='#a0a0a0 ',
                    highlight = '#e0e0e0', 
                    fullwidth=false, 
                    border= "#e0e0e0", label='', 
                    type="text", 
                    onChange,
                    onKeyUp,
                    value, 
                    multiline=false, 
                    rows=3, 
                    name, 
                    required=false, 
                    round=false,
                    backgroundColor = '#fefefe',
                   
                    prefix, 
                    textAlign = 'left',
                    suffix}) => {
    const [labelstyle, setLabelstyle] = useState({fontSize: '.8rem', color: labelColor, position: "absolute", top: "50%", marginTop: "-8px", left: round ? 24 : 16, right: round ? 24: 16, height: 16, pointerEvents: 'none', transition: 'all ease-out .2s', backgroundColor: multiline ? backgroundColor: 'transparent'});
    const [outline, setOutline] = useState('none');
    const inpRef =  useRef(null);
    useEffect(() => {
        if(inpRef) {
           if ((typeof value === 'string' && (value || '').trim()) || (type === 'number' && value !== null) ) {
            inpRef.current.value = value;
            const ls = {...labelstyle, fontSize: '.7rem', top: multiline  ? 1 : 8, marginTop: 0, left: prefix ? 32 : round ? 25 : 16, paddingTop: multiline ? 10 : 0}
            setLabelstyle(ls);
        } 
       }
    }, [value, inpRef, multiline])
    const inputFocus = () => {
        const ls = {...labelstyle, fontSize: '.7rem', top: multiline ? 3 : 8, marginTop: 0, left: prefix ? 32 : round ? 25 : 16, color: highlight,  paddingTop: multiline ? 8 : 0}
        setOutline(`2px solid ${highlight}`);
        setLabelstyle(ls);
    }

    const loseFocus = (e) => {
        const {value} = e.target;
        if(!('' || value).trim()) {
           const ls = {...labelstyle, fontSize: '.85rem',  top: "50%", marginTop: "-8px", color: labelColor}
           setLabelstyle(ls);
        
        }
        //labelstyle.color = labelColor;
        setLabelstyle(ls => ({...ls,  color: labelColor}));
        setOutline('none');
    }
    return (
        <div className="vp-form-field" style={{
            position: 'relative',
             width: fullwidth ? '100%' : type === 'number' ? 80 :120,
             borderRadius: round? 25 : 5
            
             }}>
            <label style={labelstyle}>{label}</label>
            {prefix ? <div style={{position: 'absolute', display: 'flex', alignItems: 'center', height: 56, padding: '8px 4px 0px 4px', boxSizing: 'border-box'}}>{prefix}</div> : null}
            {multiline ? <textarea onFocus={inputFocus} type={type} name={name} required={required} onBlur={loseFocus} style={{border: `1px solid ${border}`, color:color,  padding: round ? '24px 16px 0 24px' :'24px 16px 0 16px', minHeight: 56, width: '100%', outline, transition:  'outline-width .2s ease-in', boxSizing: 'border-box', borderRadius: round ? 25 : 5, backgroundColor, textAlign}}
                onChange={onChange} onKeyUp={onKeyUp} rows={rows}
                ref={inpRef}
            /> :
                <input onFocus={inputFocus} type={type} required={required} onBlur={loseFocus} style={{border: `1px solid ${border}`, color:color,  padding: round ? '16px 24px 0 24px':'16px 16px 0 16px', paddingLeft: prefix ? '32px' : round ? 24 : '16px', paddingRight: suffix ? '32px' : round ? 24 :'16px', height: 56, width: '100%', outline, transition:  'outline-width .2s ease-in', borderRadius: round ? 25 : 5, backgroundColor, textAlign}}
                onChange={onChange}  onKeyUp={onKeyUp} name={name}
                ref={inpRef}
            />
        }
          {suffix ? <div style={{position: 'absolute', right: 0, top: 0,  display: 'flex', alignItems: 'center', height: 56, padding: '8px 4px 0px 4px', boxSizing: 'border-box'}}>{suffix}</div> : null}
        </div>
    )
}

export default FormInput
import { Strings } from "../settings";

class ConfigApi {

    static #headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`
    }

    static #get_headers() {
      
        return {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`
        };

    }

    static async GetConfig() {
        try {
          
          
           
            const res =  await fetch(`${Strings.api}/config`, {
                headers: this.#get_headers()
            });
            if(!res.ok) {
                throw new Error('Network error');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }
}

export default ConfigApi;
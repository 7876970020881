import moment from "moment";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, transform } from "ol/proj";
import { XYZ } from "ol/source";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "../contexts/theme-context"
import { useStore } from "../contexts/app-context";
import MapService from "../services/map-service";
import { PlaceService, RideService, UserService } from "../services/rest";
import PlaceSearch from "./place-search"
import Spinner from "./spinner";
import SuccessScreen from "./success-screen";
import { useNavigate } from "react-router";
import FormInput from "./form-input";
import ExpandMoreIcon from "../icons/expand-more";
import ExpandLessIcon from "../icons/expand-less";
import RideSumary from "./ride-sumary";
import { DropDown, KwFormInput } from "./FormFields";
import {
    defaults as defaultInteractions
} from 'ol/interaction';
import BackArrowIcon from "../icons/back-arrow";
import RightDropDown from "./right-dropdown";
import UserCancel from "./user-cancel";
import Snack from "./snack";
import { KwButton } from "./buttons";
import { Link } from "react-router-dom";


const RiderMap = ({ride, updateRide, confirmRide, leftView, changeLeftView =  l => {}, active =  false, rideCanceledRider = () => {}}) => {
    const [map, setMap] =  useState(null);
    let originmarker = useRef(null);
    let stopmarkers = useRef([]);
    let routelayer = useRef(null);
    const [spin, setSpin] = useState(false);
    const mapMenuRef = useRef(null);
    const [menuopts, setMenuopts] =  useState({visible: false, left: 0, top:0});
    const [pickup, setPickup] = useState(null)
    const [dropoff, setDropoff] = useState(null);
    const pickupRef =  useRef(pickup);
    const dropoffReff = useRef(dropoff);
    const [estimating, setEstimating] = useState(false);
    const estimatingRef =  useRef(estimating);
    const mapRef =  useRef(null);
    const [canConfirm, setCanConfirm] = useState(false);
    const [stops, setStops] = useState([{}]);
    const stopsRef =  useRef(stops);
    const [theme, themedispatcher] =  useTheme();
    const [store, dispatch] = useStore();
    const[usrdd, setUsrdd] = useState(false);
    const navigator =  useNavigate();
    const [isPackage, setIspackage] =  useState(false);
    const [expanded, setExpanded] = useState(true);
    const slideRef =  useRef(null);
    const [ready, setReady] = useState(false);
    const readyRef =  useRef(ready);
    const [hasride, setHasRide] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [cancelmessage, setCancelMessage] = useState(null);
    const [canceling, setCanceling] = useState(false);
    const snackRef = useRef(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [sessionCountry, setSessionCountry] =  useState(null);
    const locRef =  useRef(currentLocation);
    const osmref = useRef(map);
    const activeRef = useRef(active);
   

    const [pkstep, setpkStep] = useState(1);
    

    useEffect(() => {
        themedispatcher({type: 'toolbar', value: 'none'});
        themedispatcher({type: 'toolbar-width', value: 52})
        domEventHandlers();
        AddMap()
        return () => {
            if(map) {
            map.getLayers().forEach(l => map.removeLayer(l));
            setMap(null);
            osmref.current = null;
            }
        }
    }, []);

    useEffect(() => {
       
        activeRef.current =  active;
       

    }, [active])


   


    const revGcode = useCallback(async () => {
        try {
             const res = await PlaceService.ReverseGeocodePlace(locRef.current[0], locRef.current[1]);
             
             const ccode = res?.features[0]?.properties?.address.country_code;
             console.log(ccode, res)
             setSessionCountry(ccode);
             if(ccode !=='dm') {
                
                osmref.current.getView().setCenter(fromLonLat([-61.3794,15.3092]));
                osmref.current.getView().setZoom(15);
             } else {
                osmref.current.getView().setCenter(fromLonLat([locRef.current[1], locRef.current[0]]));
                osmref.current.getView().setZoom(15);
             }
        } catch (ex) {
            throw ex;
        }
    }, []);


    useEffect(() => {
     
        if(locRef.current && osmref.current && !sessionCountry && !ride._id) {
            revGcode();
        }
    }, [currentLocation, map, revGcode, sessionCountry, ride]);

   // redraw route and markers on map when ride is active
    useEffect(() => {
        if(map) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          
           if(window.navigator.geolocation) {
               const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              };
               window.navigator.geolocation.getCurrentPosition((pos) => {
                   setCurrentLocation([pos.coords.latitude, pos.coords.longitude]);
                   locRef.current = [pos.coords.latitude, pos.coords.longitude];
                
                   const coords = [pos.coords.longitude, pos.coords.latitude];
                   
                  
                   //map.getView().setCenter(fromLonLat(coords));
                   //map.getView().setZoom(15);
               }, err=> {}, options);
           }
        if(active && store.ride?._id) {
            if (originmarker.current) {
                  map.removeLayer(originmarker.current);
            }
            for (let stop  of stopmarkers.current) {
                map.removeLayer(stop);
            }
            stopmarkers.current = [];
            originmarker.current = MapService.AddMarker({lat: store.ride.origin.lat, lon: store.ride.origin.lng, title: 'Origin'});
            map.addLayer(originmarker.current);
            let rstops = [];
            let si = 1;
            for (let stop of store.ride?.stops) {
                rstops.push(stop);
                console.log(stop);
                const mk = MapService.AddMarker({lat: stop.lat, lon: stop.lng, title: 'Stop '+si});
                stopmarkers.current.push(mk);
                map.addLayer(mk);
                si ++;
            }
            stopsRef.current = rstops;
            setStops(rstops);
            pickupRef.current = store.ride.origin;
            setPickup(store.ride.origin);
            CreateRoutePath(ride.gpx);
          
          
            
        } 
     
      //  setTimeout(() => {  this.map.updateSize();}, 3000);
    }
        

    }, [active,store.ride, map])



   

    const domEventHandlers = useCallback( () => {
        window.addEventListener('click', (e) => {
           e.stopImmediatePropagation();
            e.stopPropagation();
         
            setUsrdd(false);
        })
    }, []);

    const AddMap =() => { 
        console.log(currentLocation);
        const vmap =  new Map({
             target: 'map',
             controls: [],
             layers: [
                 new TileLayer({
                     source: new XYZ({
         
                      url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                      //url:'https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=50c413e6187a4308918d9c654d170fc5'
                      
                     })
                 })
             ],
             interactions: defaultInteractions({keyboard: false}), //possin;y set this only for packages
            
             view: new View({/*center: fromLonLat([currentLocation[1], currentLocation[0]]) ,*/ zoom: 16})
             
         });

       
        
         vmap.on('click', (e) => {
           console.log(active, activeRef.current);
        
            if(activeRef.current || (stopsRef.current.length === 3 && stopsRef.current[2]._id)) {
           
                //  setMenuopts({...menuopts, visible: false});
                  return;
              } else {
                mapClickEventHandler(e);
            }
            // const lonLat = map.getLonLatFromPixel(e.pixel);
         });

        

         vmap.on('postcompose', (e) => {
           document.querySelector('canvas').style.filter="invert(100%)";
         })

         vmap.getLayers().getArray()[0].on('postrender',function(evt){
           // console.log(evt.context.globalCompositeOperation);
            evt.context.globalCompositeOperation = "color";
            if (evt.context.globalCompositeOperation === "color") {
              // operation is supported by browser
              evt.context.fillStyle = "rgba(39,39,39," + 100 / 100 + ")";
              evt.context.fillRect(
                0,
                0,
                evt.context.canvas.width,
                evt.context.canvas.height
              );
            }
            evt.context.globalCompositeOperation = "overlay";
            if (evt.context.globalCompositeOperation === "overlay") {
            //    console.log('suported');
              // operation is supported by browser
              evt.context.fillStyle =
                "rgb(" + [39, 39, 39].toString() + ")";
              evt.context.fillRect(
                0,
                0,
                evt.context.canvas.width,
                evt.context.canvas.height
              );
            }
            evt.context.globalCompositeOperation = "source-over";
            setReady(true);
            readyRef.current = true;
          
          });
 
         osmref.current = vmap;
         setMap(osmref.current)
 
         
     };

     const logoutUSer = () => {
        window.localStorage.clear();
        dispatch({type: 'reset'});
        navigator('/');
        
     }


     const mapClickEventHandler = (e) => {
        
        setMenuopts({...menuopts, visible: false});
        let width  = window.screen.availWidth || window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
        let height  = window.screen.availHeight|| window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const dt =  e.coordinate;
        console.log(dt);
        const coords = MapService.TransformCoordinates(dt);
        console.log(coords);
        
        const left = width - e.pixel[0] <= 200 ? e.pixel[0]-200 : e.pixel[0];
        console.log(height - e.pixel[1], height, e.pixel[1]);
        const top = height - e.pixel[1] <= 177 ? e.pixel[1] - 96 : e.pixel[1]
        
        setMenuopts({visible: true, left, top, coordinates: coords});

    }





    const OriginChanged = (v) => {
        if(map && v) {
            map.removeLayer(originmarker.current);
            originmarker.current =  null;
            originmarker.current = MapService.AddMarker({lat: v.location.coordinates[1], lon: v.location.coordinates[0], title: 'Origin'});
            map.addLayer(originmarker.current);
            setPickup(v);
            updateRide({origin: {lat: v.location.coordinates[1], lng: v.location.coordinates[0]}});

        }
       
    }

    const addMarkersNoFields = (v) => {
        
        if(map) {
            const spcnt = stopsRef.current.length; 
            const ind = stopsRef.current.length;
            if(stopsRef.current.length > 3) {
                return;
            }
            const mrk = MapService.AddMarker({lat: v.location.coordinates[1], lon: v.location.coordinates[0], title: 'Stop '+spcnt});
            
            map.addLayer(mrk);
            stopmarkers.current.push(mrk);
            updateRide({stops: stopsRef.current.map(s => ({lat:s.location.coordinates[1], lng: s.location.coordinates[0]}))});
           
        }
    }

    
    
    const DestinationChanged = (v,i) => {
       console.log(v);
       const instop =  stopsRef.current.find(s => s._id === v._id);
       if(instop) {
        return;
       }
    if(stopmarkers.current.length === stopsRef.current.length) {
        
       // return;
    }
 
       
       
        if(stopsRef.current.length > 3) {
            return;
        }
        const spcnt = i+1; 
        
       
        if(map && v) {
            stopsRef.current[i] = v;
           
            if(stopmarkers.current.length > 0 &&   stopmarkers.current[i]) {
                console.log(stopmarkers.current[i]);
                alert('adding');
                map.removeLayer(stopmarkers.current[i]);
                stopmarkers.current.splice(i,1);
            }
           
            
            const mrk = MapService.AddMarker({lat: v.location.coordinates[1], lon: v.location.coordinates[0], title: 'Stop '+spcnt});
            
            map.addLayer(mrk);
            stopmarkers.current[i] = mrk;
            updateRide({stops: stopsRef.current.map(s => ({lat:s.location.coordinates[1], lng: s.location.coordinates[0]})) /*[{lat: v.location.coordinates[1], lng: v.location.coordinates[0]} ]*/});
            //console.log(st);
            //stopsRef.current = st;
            setStops(stopsRef.current);
        }
    
       

    }

    const packageInfoChanged = (e) => {
        e.preventDefault();
        let {name, value, type} =  e.target;
        if(type === 'number') {
            console.log(value, type)
            value = parseFloat(value);
        }
        console.log(type, name, store.ride);
        updateRide({package: {...store.ride.package, [name]: value}});
       

    }

    const GetPlaceFromClick = (coords, cb) => {
       
        PlaceService.GetPlacesNear(coords).then(r => {
            const clossest =  r[0];
            
            clossest.location.coordinates = [coords.lon, coords.lat];
            cb(clossest);

        });
    }

    const setPickupromMap = () => {
      
        GetPlaceFromClick({lat: menuopts.coordinates[1], lon: menuopts.coordinates[0]}, (closest) => {
            pickupRef.current = closest;
            if(expanded) {
                setPickup(closest);
            } else {
                OriginChanged(closest)
            }
        });
        setMenuopts({...menuopts, visible: false});
    }
  
    const setDropOffFromMap = () => {
         
        if(stopsRef.current.length === 3 && stopsRef.current[2]._id) {
           
          //  setMenuopts({...menuopts, visible: false});
            return;
        }
       

        GetPlaceFromClick({lat: menuopts.coordinates[1], lon: menuopts.coordinates[0]}, (closest) => {
             
          /*  const inlist = stops.find(s => s._id === closest._id);
            if (inlist) {
                return;
            }
            console.log('YAYYYY'); */
            
            if(isPackage) {
                setStops([closest]);
                stopsRef.current = [closest]
            } else {
            if(!stops[stops.length -1]._id) {
                 let tmpst = [...stops];
                 tmpst[stops.length -1] = closest;
                  setStops(tmpst);
                stopsRef.current = tmpst;
            } else {
                stopsRef.current = [...stopsRef.current, closest];
                setStops(st => [...st, closest]);
            }
            
            //if(!expanded) {
              addMarkersNoFields(closest);
               
           // }
         
        }
        });
        setMenuopts({...menuopts, visible: false});
    }


    const addStopClicked = (e) => {
     
        e.preventDefault();
        if(stops.length === 3) {
            return;
        }
        stopsRef.current = [...stopsRef.current, {}];
        setStops(s => [...s, {}])
    }

    const confirmClicked = (e) => {
        e.preventDefault();
        confirmRide();
       
    }




    const CreateRoutePath = (gpxdata) => {
        const route = MapService.AddRoutePath(gpxdata);
        
        if(routelayer.current) {
            map.removeLayer(routelayer.current)
        }
        routelayer.current =  route;
     map.addLayer(routelayer.current);
     map.getView().fit(routelayer.current.getSource().getExtent(), {
         size: map.getSize(),
         maxZoom: 15
       });
 
        
     }

     const removeStop = (e, ind) => {
        try {
         e.preventDefault();
      
         const stps = [...stops];
         stps.splice(ind,1);
         setStops(stps);
         stopsRef.current = stps;
         const found = map.getLayers().getArray().find(m => m === stopmarkers.current[ind]);
        
         
         map.removeLayer(found);
         stopmarkers.current[ind] = null;
         stopmarkers.current.splice(ind, 1);
        // console.log(stopmarkers);
         updateRide({...ride, stops: stps.map(s => ({lat:s.location.coordinates[1], lng: s.location.coordinates[0]}))});
         setCanConfirm(false);
        
        } catch (ex) {
            console.log(ex);    
        }
         
     }



     const summaryBackButtonClicked = () => {
        setCanConfirm(false);
        changeLeftView('D');
     }


     const rideDataChanged = (e) => {
        let {name, value, type} =  e.target;
        if(type === 'number') {
            value = parseFloat(value);
        }
        console.log(name, value, type);
        dispatch({type: 'update-ride', payload: {[name]: value}});

     }


     const packageTypeSelected = (v) => {
        updateRide({package: {...store.ride.package, type: v.code}});
     }


     const checkValidRide = () => {
        if(!isPackage) {
            return pickup && stops[0] && stops[0]._id ;
        } else {
            const rpackage = store.ride.package;
            return ( pickup && stops[0] && stops[0]._id  && ((rpackage?.description || '').trim() ) && rpackage?.length  && rpackage?.depth && rpackage?.width &&rpackage?.value && rpackage?.type && rpackage?.weight);
        }

     }

    

     const ChangeRideType = (rt) => {
        if(rt === 'R') {
            updateRide({package: null});
            setIspackage(false);
           
        }  else {
            for (let i=1 ; i<stops.length; i++) {
                map.removeLayer(stopmarkers.current[i]);
            }
            
            setStops([stops[0]]);
            stopsRef.current = [stops[0]];
            stopmarkers.current = [stopmarkers.current[0]]
            console.log(stops[0]);
            updateRide({stops: [{lat:stops[0]?.location?.coordinates[1], lng: stops[0]?.location?.coordinates[0]}]})
           
            setIspackage(true);
            
            
        }
        setpkStep(1);

     }
    

    const GetRideInfo = (e) => {
        setSpin(true);
        e.preventDefault();
        const stops =  ride.stops.map(s => `${s.lng},${s.lat}`).join(','); //reduce((p,c) => p+`${c.lng},${c.lat},`, '');
        console.log(ride.stops);
        RideService.GetRideDetails({ origin: `${ride.origin.lng},${ride.origin.lat}`,
            stops}).then(r => {
                r.duration = moment.duration(moment().startOf('hour').add(r.duration, 'seconds').diff(moment().startOf('hour'))).asMinutes();
                updateRide(r);
                CreateRoutePath(r.gpx);
                setEstimating(false);
                estimatingRef.current = false;
                changeLeftView('S');
                setCanConfirm(true);
                setSpin(false);
            }).catch(ex => {
                console.log(ex);
                setSpin(false);
            }) 
    }

    const cancelAction = async (a) => {
       
    
        if(a) {
            setCanceling(true);
            setCancelMessage('');
            try {
            const res =  await RideService.RiderCancelRIde(ride._id);
            if(!res.success) {
                setCancelMessage('This ride could not be cancled please try again');
            }
            setCancel(false);
            rideCanceledRider();
           
            setCanceling(false);
            snackRef.current.show('Your ride was cancled');
        } catch (ex) {
            setCancelMessage('Something went wrong while processing your request', "error");
        } finally {
            setCanceling(false);
        }

        } else {
            setCanceling(false);
            setCancel(false);
            snackRef.current.show('You aborted the action');
        }
        ClearMap();
    }


    const ClearMap = () => {
       
        for (let stop of stopmarkers.current) {
            console.log(stop);
            map.removeLayer(stop);
           
        }

        if(routelayer.current) {
            map.removeLayer(routelayer.current)
        }
        
        if (originmarker.current) {
              map.removeLayer(originmarker.current);
        }
        stopmarkers.current= [];
        originmarker.current = null;
        setStops([{}]);
        stopsRef.current = [{}];
        // just to be sure
       
    }





    let leftSide = null;

  

    switch(leftView) {
        default:
            leftSide =   <div className="place-search" style={{background: expanded ?  theme.surface1: 'transparent'}}> 
                <div style={{display: 'flex', alignItems:'center', padding: expanded ? 16 : '8px 12px' , overflow: 'hidden', boxSizing: 'border-box', pointerEvents: 'all', background: expanded? 'transparent': theme.background}} className="ride-type">
                    <button style={{border: isPackage? 'none':`1px solid ${theme.outline}`, borderRadius: 25 , marginRight: 16, backgroundColor: isPackage? 'transparent': theme.secondaryContainer, pointerEvents: 'auto', color: isPackage ? theme.primary: theme.onSecondaryContainer, width: 88}} onClick={(e) => {e.preventDefault(); ChangeRideType('R')}}>Ride</button>
                    <button style={{borderRadius: 25, border: isPackage ? `1px solid ${theme.outline}` : 'none', overflow: 'hidden', backgroundColor: isPackage? theme.secondaryContainer: "transparent", color: isPackage ? theme.onSecondaryContainer: theme.primary, pointerEvents: 'auto', width: 88}} onClick={(e) => {e.preventDefault(); ChangeRideType('P')} }>Package</button>
                   
                    <span style={{flex: '1 1 auto'}}></span>
                    <button style={{pointerEvents: 'auto'}} className="icon-button expand-form" onClick={(e) => {
                        e.preventDefault();
                       
                        setExpanded(ex => !ex)
                    }}>{!expanded ? <ExpandLessIcon fill="#fff" />: <ExpandMoreIcon fill={theme.foreground} />}</button>
                </div>
                {expanded ? <div ref={slideRef} className="mobile-slide" style={{paddingTop: 8, pointerEvents: 'auto'}}> 
                <form className="places-form" style={{paddingTop: 16, paddingBottom: 0, maxHeight: 'calc(100vh - 186px)'}}>
                    {pkstep === 1 ?<div>
                    <PlaceSearch  value={pickup} onChange={OriginChanged} fullwidth label='From where' highlight={theme.primary} color={theme.onSurface} border={theme.outline} background={theme.background} />
                    {
                       stopsRef.current.map((s,i) => (<div key={i} style={{display: "flex", alignItems: 'center'}}><PlaceSearch value={s} onChange={(v) => DestinationChanged(v, i)} fullwidth label='To where' highlight={theme.primary} background={theme.surface} color={theme.onSurface} />{i > 0 ? <button onClick={(e) => removeStop(e,i)} style={{width: 54, marginLeft: 8, borderRadius: 5, marginBottom: 24}}>-</button>:null}</div>))
                    }
                     {stops[stops.length -1]._id && stops.length < 3 && !isPackage? <div style={{marginBottom: 16}}><button style={{borderRadius: 5}} onClick={addStopClicked}>Add stop</button></div>: null}
                     <div>
                        <FormInput multiline rows={4} value={store.ride?.remarks} name="riderremarks" label="Remarks" onChange={rideDataChanged} highlight={theme.primary} color={theme.onSurface} backgroundColor={theme.surface} fullwidth />
                    </div>
                    {isPackage && pickup && stops.length === 1 && stops[0]._id ? <div className="app-buttons" style={{marginBottom: 22}}><button onClick={(e) => {
                        e.preventDefault();
                        setpkStep(2);
                    }
                    }>Package Details</button></div>: null}
                    </div> : null}
                    {isPackage &&  store.ride.origin  && store?.ride?.stops?.length === 1 && pkstep === 2? 
                    <div className="mobile-slide">
                        <div style={{display: 'flex', marginBottom: 16}}>
                        <span style={{flex: '1 1 auto'}}></span>
                            <button onClick={(e) => {
                            e.preventDefault();
                            setpkStep(1);
                        }} className="icon-button" style={{width: 48, height: 48, backgroundColor: theme.surfaceVariant}}><BackArrowIcon /> </button></div>
                        <div>
                        <FormInput multiline name="description" rows={4} required value={store.ride?.package?.description} onChange={packageInfoChanged}  color={theme.onSurface} highlight={theme.primary} backgroundColor={theme.surface} fullwidth label="Package Description" />
                        </div>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                        <div style={{marginRight: 8}}>
                            <FormInput required name="length" value={store?.ride?.package?.length} onChange={packageInfoChanged} color={theme.onSurface} backgroundColor={theme.surface} fullwidth highlight={theme.primary} label="Length(CM)" type="number" />
                        </div>
                        <div  style={{marginRight: 8}}>
                            <FormInput required name="width" value={store?.ride?.package?.width} onChange={packageInfoChanged} fullwidth  color={theme.onSurface} backgroundColor={theme.surface} highlight={theme.primary} label="width(CM)" type="number" />
                        </div>
                        <div>
                            <FormInput required name="depth" value={store?.ride?.package?.depth} onChange={packageInfoChanged} color={theme.onSurface} backgroundColor={theme.surface} fullwidth highlight={theme.primary} label="height(CM)" type="number" />
                        </div>
                        </div>
                        <div>
                        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 8}}>
                            <div>
                             <FormInput required name="weight" value={store?.ride?.package?.weight} onChange={packageInfoChanged} color={theme.onSurface} backgroundColor={theme.surface} fullwidth highlight={theme.primary} label="Weight(KG)" type="number" />
                            </div>
                            <div>
                              <FormInput color={theme.onSurface} backgroundColor={theme.surface} name="value" value={store?.ride?.package?.value} onChange={packageInfoChanged} fullwidth highlight={theme.primary} label="Aprox value ($EC)" type="number" />
                            </div>
                        </div>
                        <div style={{paddingBottom: 40}}>
                            <DropDown items={
                                [{name: 'BOX', code: 'BX'},
                                {name: 'BAG', code: 'BG'},
                                {name: 'CRATE', code: 'CR'}
                            ]
                            }
                             valueField = 'code'
                             displayField='name'
                             menuColor={theme.secondaryContainer} 
                             selectBackground = {theme.surface}
                             labelColor={theme.onSurface}
                             type="flat"
                             menuItemTextColor={theme.onSecondaryContainer}
                             name="type" value={store?.ride?.package?.type} onChange={packageTypeSelected} 
                             color={theme.onSurface} 
                            
                            fullWidth highlight={theme.primary} 
                            label="Package Type" />
                        </div>
                        </div>
                           
                    </div> : null}
                    
                                      
                    <div  style={{paddingBottom: pickup && stops[0] && stops[0]._id ? 24 : 0}}>
                       {checkValidRide() ? <KwButton onClick={GetRideInfo} label={isPackage ? 'Request delivery':'Request ride'} background={theme.primary} fullwidth hoverColor={theme.primaryContainer} color={theme.onPrimary} hoverTextColor={theme.onPrimaryContainer} border={theme.outline} highlight={theme.primary} >
                       {spin ? <Spinner size={16} color={theme.primaryContainer} /> :null}
                       </KwButton>: null}
                    </div>

                </form>
               
                
                
                </div> : null}
                </div>
                break;

                case 'B':
                    leftSide=( <div style={{textAlign: "center"}}>
                        <SuccessScreen text={'Success you ride was successfully booked. Please wait for confirmation from one of our drivers'} />
                        </div>
                    )
                    break;
                case 'S':
                    leftSide = (
                    <div className="summary-section rounded-card" style={{padding: '16px' , pointerEvents: 'auto', backgroundColor: theme.surface1, color: theme.onSurfaceVariant}}>
                        <RideSumary ride={store.ride} title= {ride.package?.description ? "Package delivery details" :"Your ride details"} backButton backButtonClicked={summaryBackButtonClicked} active={active} />
                        {ride.cost ? <div className="ride-cost">
                       <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                        <p style={{padding: 0, margin: 0}} className="fare-heading">Cost</p>
                        <div style={{display: "flex", alignItems: "center",justifyContent: "flex-start", marginTop: 12, fontWeight: 600}}>
                        <div className="ride-metrics">
                          A distance of {(ride.distance / 1000).toFixed(2)} KM in about {ride.duration?.toFixed(0)} Minutes</div>
                        </div>
                       </div>
                        
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{fontWeight: 600, fontSize: "1.5rem"}}>{ride.cost.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</div>
                    </div>
                </div>: null}
                        {canConfirm ? 
                        <div>
                            <KwButton
                                label="Confirm ride"
                                background={theme.primary}
                                color={theme.onPrimary}
                                fullwidth
                                onClick={confirmClicked}
                                hoverColor ={theme.primaryContainer}
                                hoverTextColor={theme.onPrimaryContainer}
                                border={theme.outline}
                                highlight={theme.primary}
                            />
                            </div>: null}
                        {ride._id &&  ride.status >= 0 && ride.status <=1   ?   <div><KwButton onClick={() => setCancel(true)}
                            background={theme.primary} 
                            color={theme.onPrimary} 
                            fullwidth 
                            label="Cancel Ride"
                            hoverColor={theme.primaryContainer}
                            border={theme.outline}
                            highlight={theme.primary}
                            hoverTextColor={theme.onPrimaryContainer}
                             /> </div>: null}
                    </div>
                    )
                    break;

    }


    
    return (
        <div className="ride-page" style={{overflow: 'hidden', position: 'relative'}}>
        
        <div  className="map" id="map">
        {ready ? 
        <>
        <div className="mobile-toolbar">
            <RightDropDown />
        </div>
        <div className="app-bar map-bar" style={{backgroundColor: theme.background, borderBottom: `1px solid ${theme.outline}` }}>
            <div className="app-bar-body-wide" style={{alignItems: 'center', pointerEvents: 'auto', color: theme.onSurfaceVariant}} >
                <div className="title bar-left">
                <span className="brand title" style={{height: 48}}><Link to="/" style={{display: 'flex', alignItems: 'center'}}><div style={{padding: 4, borderRadius:'50%', overflow: "hidden", height: 48, width: 48, boxSizing: "border-box", display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: theme.primary, border: `2px solid ${theme.primary}`, textAlign: "center", marginRight: 8}}><img src="logo192.png"  height={38} alt="vepp" style={{ boxSizing: "border-box"}}/></div> Vepp</Link></span>
                </div>
                <RightDropDown />
                
            </div>
         </div>
        <div className="selection-form " style={{color: theme.foreground, background: expanded? theme.background:  'transparent', border: expanded? `1px solid ${theme.background}` : 'none' }} onClick={() => setMenuopts(false)}>
         
        {leftSide}
          
        </div>
        </> : null}
        <div ref={mapRef} className="the-map">
           
                {
                menuopts.visible && !canConfirm ? <ul ref={mapMenuRef} className="map-menu card" style={{left: menuopts.left, top: menuopts.top, background: theme.background, color: theme.foreground}} >
                    <li style={{backgroundColor: theme.surface1}} onClick={setPickupromMap}>Set as pickup location</li>
                    <li style={{backgroundColor: theme.surface1}} onClick={setDropOffFromMap}>{isPackage ? 'Set as drop off':  'Add to stops'}</li>
                </ul> 
                :null
               }                

        </div>
        </div>
        {cancel ? <UserCancel action={(a) => cancelAction(a)} spinner={canceling} message={cancelmessage} /> : null}
        <Snack  show={snackRef} />
    </div>
    )
}

export default RiderMap;
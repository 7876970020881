const Strings = {
   // api: 'http://localhost:3991/apiv1',
    api: 'https://veppz.com/service/apiv1',
    apikey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOiIyMDIyLTA2LTA5VDEyOjAyOjQ0LjY2N1oiLCJhcHAiOiI2MmExZGRkMTRkNWVlMWY0NWQ2OTIyMjAiLCJjbGllbnRpZCI6ImZkMGFDaVVkcnpSd0VLTzZ3RXN0Qm9LY1VvVkJMVlpIIiwiZG9tYWluIjoidmVwcC5jb20ifQ%3D%3D.yEZAJP1aX%2BE1tY8%2FWhrFEx4L6oteVClQfUwn%2FnVEPTY%3D',
   // socketurl: 'ws://localhost:3559'
    socketurl: 'wss://veppz.com/websocket/',
    //test
   // paykey: 'AYDYxT6oBI536VYvmzczmgZx1G44jaW8yIHTwnzCy9pqhinqYRUXFK9G2mVlTHyFDmnsp94xl3IW6pla'
    //prod
  
}



export {Strings} 
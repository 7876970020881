import { useTheme } from "../contexts/theme-context"
import CheckCircle from "../icons/check-circle"

const  SuccessScreen = ({text}) => {
    const [theme] =  useTheme();
    return (<div className="success-screen">
        <div style={{display: "flex", flexDirection:"column", alignItems: "center", textAlign: 'center'}}>
        <CheckCircle height={96} width={96} fill={theme.accent} />
        </div>
        <div style={{color: theme.accent, fontSize: "1.4rem", marginTop: 32}}>{text}</div>
    </div>)

}

export default SuccessScreen
import { Strings } from "../settings"
const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`,
};

const get_headers = () => {
    return  {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${window.localStorage.getItem('vep-token')}`,
    };
}


const DriverApi = () => {
   const  RegisterDriver = (data) => {
       return fetch(`${Strings.api}/driver/register`, {
           method: 'POST',
           body: JSON.stringify(data),
           headers: get_headers()
       }).then(res => {
           if(!res.ok) {
               throw new Error('Network error');
           }
           return res.json()
       });

    }


    const GetDriverByUid = (uid, signal) => {
        return fetch(`${Strings.api}/driver/userid/${uid}`, {
            headers: get_headers(),
            signal
        }).then(r => {
            if(!r.ok) {
                throw new Error('oops something went wrong');
            }
            return r.json();
        });

    }


    const GetDriver = (id, signal) => {
       console.log(headers);
        return fetch(`${Strings.api}/driver/${id}`, {
            headers: get_headers(),
            signal
        }).then(r => {
            if(!r.ok) {
                throw new Error('oops something went wrong');
            }
            return r.json();
        });

    }


    const ValidateNewDriver = async (data) => {
        try {
            const res = await fetch(`${Strings.api}/driver/validate`,{
                method: 'POST',
                headers: get_headers(),
                body: JSON.stringify(data)
            });
            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }


    const UpdateDriver = async (id, data) => {
        try {
            const res = await fetch (`${Strings.api}/driver/${id}`, {
                headers: get_headers(),
                method: 'PATCH',
                body: JSON.stringify(data)
            });

            if(!res.ok) {
                throw new Error('Oops something went wrong');
            }
            return await res.json();

        } catch (ex) {
            throw ex;
        }
    }

    /*const SavePhoto = async (uid, data) => {
        const res = await fetch(`${Strings.api}/user/profilepic/${uid}`, {
            method: 'PATCH',
           
            body: data
        })
        if(!res.ok) {
                throw new Error('Network error');
        }
        return await res.json();
        

    }*/


    return {
        RegisterDriver,
        GetDriverByUid,
        ValidateNewDriver,
        UpdateDriver,
        GetDriver
    }
}

export default DriverApi